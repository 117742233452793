import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../app.constants';
import { Observable } from 'rxjs';
import { LookupChild } from '../model/lookupchild';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private lookUpParentUrl: string;
  private lookUpChildUrl: string;
  private lookUpChildByParentUrl: string;
  private allLookUpChildByCompanyIDUrl: string;


  constructor(private httpClient: HttpClient, private config: Configuration) {

    this.lookUpParentUrl = this.config.lookUpParentApiUrl;
    this.lookUpChildUrl = this.config.lookUpChildApiUrl;
    this.lookUpChildByParentUrl = this.config.lookUpChildByParentApiUrl;
    this.allLookUpChildByCompanyIDUrl = this.config.allLookUpChildByCompanyIDApiUrl;

  }

  public getAllLookupParent<T>(): Observable<T> {
    return this.httpClient.get<T>(this.lookUpParentUrl);
  }

  public getLookupChildById<T>(id: number): Observable<T> {
    return this.httpClient.get<T>(this.lookUpChildUrl + '/' + id);
  }

  public addLookupChild<T>(lookupChild: LookupChild): Observable<T> {
    return this.httpClient.post<T>(this.lookUpChildUrl, lookupChild);
  }

  public editLookupChild<T>(lookupChild: LookupChild): Observable<T> {
    return this.httpClient.put<T>(this.lookUpChildUrl, lookupChild);
  }

  public deleteLookupChild<T>(lookupChild: LookupChild): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: lookupChild,
    };
    return this.httpClient.delete<T>(this.lookUpChildUrl, options);
  }
  public getLookupChildByParent<T>(parentName:string): Observable<T> {
    let params = new HttpParams({ fromString: 'parentGroupName=' + parentName});
    return this.httpClient.get<T>(this.lookUpChildByParentUrl, { params: params });
  }
  public getAllLookupChildByCompanyID<T>(companyID: string): Observable<T> {
    let params = new HttpParams({ fromString: 'companyID=' + companyID });
    return this.httpClient.get<T>(this.allLookUpChildByCompanyIDUrl, { params: params });
  }
}
