import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../app.constants';
import { Observable, Subject } from 'rxjs';
import { License } from '../model/license';
import { LicenseHistory } from '../model/licensehistory';
import { CompanyDetails } from '../model/company';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private licenseDetailsUrl: string;
  public licenseHistoryDetailsUrl: string;
  public getLicenseByCompanyIDUrl: string;
  public getLicenseHistoryByCompanyIDUrl: string;
  public isLicenseExpiredUrl: string;
  planChange: Subject<boolean> = new Subject<boolean>();

  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.licenseDetailsUrl = this.config.licenseDetailsApiUrl;
    this.licenseHistoryDetailsUrl = this.config.licenseHistoryDetailsApiUrl;
    this.getLicenseByCompanyIDUrl = this.config.getLicenseByCompanyIDApiUrl;
    this.getLicenseHistoryByCompanyIDUrl = this.config.getLicenseHistoryByCompanyIDApiUrl;
    this.isLicenseExpiredUrl = this.config.isLicenseExpiredApiUrl;
  }

  public getAllLicense<T>(): Observable<T> {
    return this.httpClient.get<T>(this.licenseDetailsUrl);
  }

  public getAllLicenseHistory<T>(): Observable<T> {
    return this.httpClient.get<T>(this.licenseHistoryDetailsUrl);
  }

  public getAllLicenseHistoryByCompanyId<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.licenseHistoryDetailsUrl + '/' + companyId);
  }

  public addLicense<T>(license: License): Observable<T> {
    return this.httpClient.post<T>(this.licenseDetailsUrl, license);
  }

  public addLicenseHistory<T>(licenseHistory: LicenseHistory): Observable<T> {
    return this.httpClient.post<T>(this.licenseHistoryDetailsUrl, licenseHistory);
  }

  public updateLicense<T>(license: License): Observable<T> {
    return this.httpClient.put<T>(this.licenseDetailsUrl, license);
  }

  public deleteLicense<T>(license: License): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: license,
    };
    return this.httpClient.delete<T>(this.licenseDetailsUrl, options);
  }

  public getLicenseByCompanyID<T>(companyID: string): Observable<T> {
    return this.httpClient.get<T>(this.getLicenseByCompanyIDUrl + '?companyID=' + companyID);
  }
  public getLicenseHistoryByCompanyID<T>(companyID: string): Observable<T> {
    return this.httpClient.get<T>(this.getLicenseHistoryByCompanyIDUrl + '?companyID=' + companyID);
  }

  public isLicenceExpired<T>(companyID: string): Observable<T> {
    return this.httpClient.get<T>(this.isLicenseExpiredUrl + '?companyID=' + companyID);
  }
}
