import { Component, OnInit, ViewChild, ElementRef, TemplateRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetails } from '../model/userdetail';
import { Subscription } from 'rxjs';
import { UserdetailsService } from '../services/userdetails.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import validationEngine from 'devextreme/ui/validation_engine';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Configuration } from '../app.constants';
import { UserDataUI } from '../model/userDataUI';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean = false;
  //isLoading: boolean = true;
  companyEmail: string = '';

  emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @ViewChild('passwordRestPopup', { static: true }) passwordRestPopup: ElementRef;
  @ViewChild('userInactiveComponent', { static: true }) userInactiveComponent: TemplateRef<any>;

  users: UserDetails[];
  subscriptions: Subscription[] = [];
  currentUser: UserDetails;
  modalOption: NgbModalOptions = {};
  userDataUI: UserDataUI = null;
  inactiveType: string = '';

  constructor(
    private router: Router, private userservice: UserdetailsService, private config: Configuration, private toastr: ToastrService,
    private modalService: NgbModal, private httpClient: HttpClient, private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.currentUser = new UserDetails;
    this.currentUser.userEmail = "";
    this.currentUser.userPassword = "";
    this.companyEmail = '';
    //this.verifyConnection();
    this.ngbModalOptions();
  }

  private ngbModalOptions() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.centered = true;
    this.modalOption.size = "sm";
  }

  setFocus(e) {
    setTimeout(() => {
      e.component.focus();
    }, 0);
  }

  onSubmit(validationName) {
    this.spinnerService.show();
    this.currentUser.userEmail = this.currentUser.userEmail.trimLeft();
    this.currentUser.userEmail = this.currentUser.userEmail.trimRight();
    let validationResult = validationEngine.validateGroup(validationName);
    if (validationResult.isValid) {
      this.subscriptions.push(
        this.userservice
          .validateUser<UserDetails>(this.currentUser)
          .subscribe(
            (data: any) => {
              if (data) {
                
                this.currentUser = data;
                this.userDataUI = new UserDataUI();
                this.userDataUI.companyId = data.companyId;
                this.userDataUI.userId = data.userId;
                if (data.isCompanyActive) {
                  if (data.isUserActive) {
                    this.isLoggedIn = true;
                    //this.cookie.delete("UserData");
                    //this.cookie.set("UserData", JSON.stringify(data));
                    sessionStorage.clear();
                    sessionStorage.setItem('token', data.token);
                    sessionStorage.setItem('UserData', JSON.stringify(this.userDataUI));
                    //this.spinnerService.hide();
                    this.router.navigate(['/dashboard']);
                  }
                  else {
                    this.spinnerService.hide();
                    this.inactiveType = 'User';
                    this.modalOption.size = "lg"
                    this.modalService.open(this.userInactiveComponent, this.modalOption);
                  }
                }
                else {
                  this.spinnerService.hide();
                  this.inactiveType = 'Company';
                  this.modalOption.size = "lg"
                  this.modalService.open(this.userInactiveComponent, this.modalOption);
                }
              }
              else {
                this.spinnerService.hide();
                this.currentUser.userEmail = "";
                //this.currentUser.userPassword = "";
                console.log("Invalid credentials")
                //notify({ message: 'Invalid credentials', width: 300, position: 'top right' }, 'error', 600);
                this.toastr.error('Invalid credentials.');

              }
            },
          error => {
            this.spinnerService.hide();
            this.currentUser.userEmail = "";
            //this.currentUser.userPassword = "";
            let message: string = error.error ? error.error.message ? error.error.message : "Unable to login." : "Unable to login.";
            let type: string = message == "Unable to login." ? 'error' : 'warning';
            //notify({ message: message, width: 300, position: 'top right' }, type, 6000);
            type == 'warning' ? this.toastr.warning(message) : this.toastr.error(message);
            }));
    }
    else {
      this.spinnerService.hide();
      //this.currentUser.userEmail = "";
      //this.currentUser.userPassword = "";
      //notify({ message: 'Please verify the login details', width: 300, position: 'top right' }, 'warning', 6000);
      this.toastr.warning('Please verify the login details.');
    }

  }

  getAllUsers() {
    this.subscriptions.push(
      this.userservice
        .getUserDetails<UserDetails[]>()
        .subscribe(
          (data: UserDetails[]) => {
            //console.log(data);
            this.users = data;
          },
          error => {
            console.error(error);
          }));
  }
  openResetPassword() {
    this.modalOption.size = "sm"
    this.modalService.open(this.passwordRestPopup, this.modalOption);
  }
  resetPassword(pswResetForm, validationGroupName) {
    let validationResult = validationEngine.validateGroup(validationGroupName);
    if (validationResult.isValid) {
      let emailId = pswResetForm.formData.companyEmail;
      let params = new HttpParams({
        fromString: 'email=' + emailId
      });
      this.spinnerService.show();
      this.subscriptions.push(
        this.httpClient.get(this.config.resetPasswordApiUrl, { params: params }).subscribe((data: any) => {
          //notify({ message: 'Password reset link sent successfully to ' + emailId, width: 300, position: 'top right' }, 'success', 6000);
          this.toastr.success('Password reset link sent successfully to ' + emailId+'.');
          this.modalService.dismissAll();
          this.spinnerService.hide();

        }, ((error: any) => {
            //notify({ message: 'Invalid Email!!', width: 300, position: 'top right' }, 'warning', 6000);
            this.toastr.warning('Invalid Email.' + emailId);
          this.spinnerService.hide();
        }))
      );
     
    }
    else {
      //notify({ message: 'Enter valid email', width: 300, position: 'top right' }, 'warning', 600);
      this.toastr.warning('Enter valid email.');
    }
  }
  modalClose() {
    this.modalService.dismissAll();
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
  }
}
