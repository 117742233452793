import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Configuration } from '../app.constants';
import { Subscription, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  currentSection = 'home';
  isFaqVisible: boolean = false;
  private enquiryUrl: string;
  version: string = '';
  @ViewChild('contactForm', { static: false }) contactForm: NgForm;
  @ViewChild('tryShocModal', { static: true }) tryShocModal: ElementRef;
  subscription: Subscription[] = [];
  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    let element = document.getElementById("navbar");
    let back2top = document.getElementById("back2top");
    if (window.pageYOffset > 100) {
      element.classList.add('shoc-bg');
      back2top.classList.remove('back2top-hide');
    } else {
      element.classList.remove('shoc-bg');
      back2top.classList.add('back2top-hide');
    }
    //if (window.pageYOffset < 730) this.currentSection = 'home';
    //else if (window.pageYOffset < 2964 && window.pageYOffset > 730) this.currentSection = 'features';
    //else if (window.pageYOffset < 3673 && window.pageYOffset > 2964) this.currentSection = 'pricing';
    //else this.currentSection = 'contact';
  }
  private obsSubsription: Subscription;

  constructor(private config: Configuration, private httpClient: HttpClient, private spinnerService: NgxSpinnerService,
    private toastr: ToastrService, private modalService: NgbModal, private router: Router) {
    this.enquiryUrl = config.enquiryApiUrl;
  }

  ngOnInit() {
    this.version = this.config.Version;
    document.getElementById("back2top").classList.add('back2top-hide');
    this.obsSubsription = interval(5000).subscribe(() => this.openTryShocModal());
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    if (this.isFaqVisible == true) {
      section = 'home';
    }
    this.isFaqVisible = false;
    this.currentSection = section;
    document.querySelector('#' + section).scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (window.innerWidth && window.innerWidth <= 767) {
      let element2 = document.getElementsByClassName("navbar-collapse");
      for (let i = 0; i < element2.length; i++) {
        element2[i] && element2[i].classList.remove("show");
      }
    }
  }
  openFaq() {
    this.scrollTo('home');
    this.isFaqVisible = true;
  }
  choosePlan() {

  }
  onSubmit() {
    this.spinnerService.show();
    this.subscription.push(  
      this.httpClient.post(this.enquiryUrl, this.contactForm.value).subscribe((data: any) => {
        //notify({ message: 'Request Submitted', width: 300, position: 'top right' }, 'success', 600);
        this.toastr.success('Request submitted.');
        this.contactForm.resetForm();
        this.spinnerService.hide();

      }, ((error: any) => {
          //notify({ message: 'We will get back you soon!!', width: 300, position: 'top right' }, 'success', 600);
          this.toastr.success('We will get back you soon!!');
          this.contactForm.resetForm();
          this.spinnerService.hide();
      }))
    );
    //console.log(this.contactForm.value);
  }
  openTryShocModal() {
    let element = document.getElementsByClassName('try-shoc');
    if (element && element.length == 0) {
      this.modalService.open(this.tryShocModal, { backdrop: 'static', centered: true, size: 'lg', windowClass: 'try-shoc-holder', backdropClass: 'try-shoc-backdrop' });
      this.obsSubsription.unsubscribe();
    }
  }
  closeTryShocModal(action: string) {
    this.modalService.dismissAll();
    if (action == 'close') {
      this.obsSubsription = interval(300000).subscribe(() => this.openTryShocModal());
    }
    if (action == 'register') {
      this.obsSubsription.unsubscribe();
      this.router.navigate(['/register']);
    }
    if (action == 'login') {
      this.obsSubsription.unsubscribe();
      this.router.navigate(['/login']);
    }
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
    this.obsSubsription.unsubscribe();
  }
}
