import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthenticationService } from '../services/user-authentication.service';
import { Router } from '@angular/router';
import { UserdetailsService } from '../services/userdetails.service';
import { Subscription, Observable, interval } from 'rxjs';

import { UserDetails } from '../model/userdetail';
import { LookupChild } from '../model/lookupchild';
import { LookupService } from '../services/lookup.service';
import { Permission } from '../model/permission';
import { UserPermissionService } from '../services/user-permission.service';
import { ScreenList } from '../model/ScreenList';
import { ScreenListService } from '../services/screen-list.service';
import { Configuration } from '../app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDataUI } from '../model/userDataUI';
import { LoginHistory } from '../model/loginhistory';
import { LoginHistoryService } from '../services/login-history.service';
import { LicenseService } from '../services/license.service';
import { CompanyService } from '../services/company.service';
import { CompanyDetails } from '../model/company';
import { ShocModules } from '../model/shocModules';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit, OnDestroy {

  version: string = ''; //Moved to app.constants.ts

  userDataUI: UserDataUI = null;
  subscriptions: Subscription[] = [];
  userRole: LookupChild[] = [];
  isMasterCompany: boolean = false;
  loggedInUserInitial: string = '';
  loggedInUser: UserDetails = null;
  permissions: Permission[] = [];
  screenList: ScreenList[] = [];
  _currentCompanyId: string = '';
  lookupLoading: boolean = false;
  isExpired: boolean = false;
  shocModules: ShocModules[] = [];

  userSessionTimeStamp;


  constructor(
    private router: Router, private userAuthService: UserAuthenticationService,
    private permissionService: UserPermissionService, private screenListService: ScreenListService,
    private userDetailsService: UserdetailsService, private lookupService: LookupService,
    private config: Configuration, private spinnerService: NgxSpinnerService,
    private loginHistoryService: LoginHistoryService, private licenseService: LicenseService,
    private companyService: CompanyService, private toastr: ToastrService
  ) {
    this.userAuthService.permissionChange.subscribe(data => this.getPermission());
    this.userAuthService.userDetailsChange.subscribe(data => this.getLoggedInUser());
    this.licenseService.planChange.subscribe(data => this.isExpired = data);
    this.companyService.companyUpdated.subscribe(data => {
      this.isPlanExpired();
      if (this.userDataUI.masterCompanyId == Guid.EMPTY) {
        this.getUserDataUI();
      }
    });
  }

  ngOnInit() {
    this.version = this.config.Version;
    //this.userDataUI = JSON.parse(this.cookie.get("UserData"));
    //   this.userDataUI = JSON.parse(sessionStorage.getItem("UserData"));
    this.getUserDataUI();
    //this.getCurrentCompany();
    //this.updateloginHistory();
    //this.getPermission();
    //this.getLoggedInUser();
    //this.getAllLookupChildItemsByType();
    //this.getScreenModules();
    //this.updateUserSessionTimeStamp();
  }

  getUserDataUI() {

    this.userDataUI = JSON.parse(sessionStorage.getItem("UserData"));

    let userdetails = new UserDetails();
    userdetails.userId = this.userDataUI.userId;
    userdetails.companyId = this.userDataUI.companyId;
    this.subscriptions.push(
      this.userDetailsService
        .getUserdataUI<UserDetails>(userdetails)
        .subscribe(
          (data: any) => {
            this.userDataUI = data;
            sessionStorage.setItem('UserData', JSON.stringify(data));
            this._currentCompanyId = data.companyId.toString();
            this.isMasterCompany = data.companyId == data.masterCompanyId ? true : false;
            //this.getCurrentCompany();
            this.updateloginHistory();
            this.getPermission();
            this.getLoggedInUser();
            this.getAllLookupChildItemsByType();
            this.getScreenModules();
            this.updateUserSessionTimeStamp();
          }, error => {
            console.log(error);
            //notify({ message: 'Error while loading', width: 300, position: 'top right' }, 'error', 600);
            this.toastr.error('Error while loading.');
          }
        ));
  }
  updateUserSessionTimeStamp() {

    this.userSessionTimeStamp = interval(5 * 60 * 1000).pipe().subscribe(x => {
      this.subscriptions.push(this.userDetailsService
        .updateUserSession<UserDetails>(this.userDataUI).subscribe())
    });
  }
  getPermission() {
    this.lookupLoading = true;
    this.spinnerService.show();
    this.subscriptions.push(
      this.screenListService
        .getAllScreens<ScreenList[]>()
        .subscribe((data: ScreenList[]) => {
          if (data) {
            this.screenList = data;
            this.screenListService.screenList = data;
            this.permissionService
              .getUserPermissionByRoleId(this.userDataUI.userRoleId, this.userDataUI.companyId)
              .subscribe((per: any[]) => {
                if (per != null && per != undefined && per.length > 0) {
                  let perm: Permission[] = per;
                  if (perm.every(x => x.canEdit == null && x.canView == null && x.canEdit == null && x.canDelete == null && x.canPrint == null)) {
                    this.setPermission();
                  }
                  else {
                    this.permissionService.userPermission = per;
                    this.permissions = per;
                    this.getCurrentCompany();
                    this.permissionService.reloadPermission.next();
                  }

                  //console.log("ALL USER PERMISSIONS\n", this.permissions);
                  //console.log("ALL SCREEN LIST     \n", this.screenList);
                }
                this.lookupLoading = false;
                this.spinnerService.hide();
              },
                (error) => {
                  console.log(error);
                  //notify({ message: 'Error while loading', width: 300, position: 'top right' }, 'error', 600);
                  this.toastr.error('Error while loading.');
                  this.spinnerService.hide();
                }
              )
          }
        },
          (error) => {
            console.log(error);
            //notify({ message: 'Error while loading', width: 300, position: 'top right' }, 'error', 600);
            this.toastr.error('Error while loading.');
            this.spinnerService.hide();
          })
    )
  }
  getLoggedInUser() {
    this.spinnerService.show();
    this.subscriptions.push(
      this.userDetailsService
        .getUserDetailsById<UserDetails>(this.userDataUI.userId.toString())
        .subscribe((data: UserDetails) => {
          if (data) {
            this.loggedInUser = data;
            let _loggedInUserName = this.loggedInUser.userName;
            this.permissionService.loggedUserIsClinetAdmin = (data.isClientAdmin == null) ? false : data.isClientAdmin;
            let matches = _loggedInUserName.match(/\b(\w)/g);
            this.loggedInUserInitial = matches.join('').toUpperCase();
          }
          this.spinnerService.hide();
        },
          (error) => {
            console.log(error);
            //notify({ message: 'Error while loading', width: 300, position: 'top right' }, 'error', 600);
            this.toastr.error('Error while loading.');
            this.spinnerService.hide();
          })
    )
  }
  getAllLookupChildItemsByType() {
    this.subscriptions.push(
      this.lookupService
        .getLookupChildByParent('User Role')
        .subscribe(
          (data: LookupChild[]) => {
            //console.log(data);
            this.userRole = data;
          },
          (error) => {
            console.log(error);
            //notify({ message: 'Error while loading', width: 300, position: 'top right' }, 'error', 600);
            this.toastr.error('Error while loading.');
          }
        )
    );
  }
  getRoleName(id) {
    return this.userRole.length > 0 ? this.userRole.find(x => x.childId == id).childName : '';
  }
  getScreenPermission(screenName: string) {
    //ScreenCheck
    if (this.screenList.length > 0 && this.permissions.length > 0) {
      let screenId = this.screenList.find(x => x.screenName == screenName).screenId;
      let _permission = this.permissions.find(y => y.screenId == screenId);
      if (_permission.canView == true) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }
  logout() {
    this.spinnerService.show();
    let userData = sessionStorage.getItem("UserData");
    if (userData) {
      //let logindata: LoginHistory = JSON.parse(this.cookie.get("LoginData"));
      let logindata: LoginHistory = JSON.parse(sessionStorage.getItem("LoginData"));
      logindata.description = 'Application Logout';
      this.loginHistoryService
        .updateLoginHistory(logindata)
        .subscribe(data => {
          //this.cookie.deleteAll();
          sessionStorage.clear();
          this.userAuthService.loggedInChange.next(false);
          this.spinnerService.hide();
          this.router.navigate(['/home']);
        },
          error => {
            sessionStorage.clear();
            this.userAuthService.loggedInChange.next(false);
            this.spinnerService.hide();
            this.router.navigate(['/home']);
          })
    }
  }
  isPlanExpired() {
    //To check wheather plan is experied
    let userDataUI = JSON.parse(sessionStorage.getItem("UserData"));
    this.subscriptions.push(
      this.licenseService.isLicenceExpired(userDataUI.companyId).subscribe((data) => {
        if (data == false || userDataUI.companyId == userDataUI.masterCompanyId) {
          this.isExpired = false;
        }
        else {
          this.isExpired = true;
          this.router.navigate(['/settings/plans']);
        }
      }, error => {
        console.log(error);
      })
    )
    //let licensEndDate = formatDate(this.userDataUI.expire, 'yyyy-MM-dd', 'en');
    //let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    //if (licensEndDate < todayDate) {
    //  this.isExpired = true;
    //  this.router.navigate(['/settings/plans']);
    //}
    //else {
    //  this.isExpired = false;
    //}
  }
  getCurrentCompany() {
    this.subscriptions.push(
      this.companyService
        .getCompanyById(this.userDataUI.companyId)
        .subscribe((data: CompanyDetails) => {
          if (data) {
            let currentCompany: CompanyDetails = data;
            if (currentCompany) {
              if (currentCompany.stripeCustomerCode == null) {
                this.isExpired = true;
                this.router.navigate(['/settings/company-detail/' + this.userDataUI.companyId]);
              }
              else {
                this.isMasterCompany ? this.isExpired = false : this.isPlanExpired();
              }
            }
          }
        },
          error => {
            console.log(error);
          })
    )
  }
  updateloginHistory() {
    let logindata = sessionStorage.getItem("LoginData")
    if (logindata == null || logindata == undefined) {
      let userDataUi: UserDataUI = JSON.parse(sessionStorage.getItem("UserData"));
      let loginHistory = new LoginHistory();
      loginHistory.companyId = userDataUi.companyId;
      loginHistory.logInTime = new Date();
      loginHistory.userId = userDataUi.userId;
      this.loginHistoryService.addLoginHistory(loginHistory)
        .subscribe(data => {
          //this.cookie.set("LoginData", JSON.stringify(data));
          sessionStorage.setItem("LoginData", JSON.stringify(data));
        },
          error => {
            console.log(error);
          }
        )
    }
  }
  getScreenModules() {
    this.subscriptions.push(
      this.screenListService
        .getScreenModules()
        .subscribe((data: ShocModules[]) => {
          if (data) {
            this.shocModules = data;
            //console.log(this.shocModules);
          }
        },
          error => {
            console.log(error);
          })
    )
  }
  getModuleVisibility(screenName) {
    //ScreenCheck
    if (this.screenList && this.shocModules && this.permissions && this.screenList.length > 0 && this.shocModules.length > 0 && this.permissions.length > 0) {
      let shocModules: ShocModules = this.shocModules.find(sm => sm.shocModuleName == screenName);
      let screenlist: ScreenList[] = this.screenList.filter(sl => sl.shocModuleId == shocModules.shocModuleId && sl.screenName != "Asset Detail" && sl.screenName != "User Detail" && sl.screenName != "Custom Plan Detail" && sl.screenName != "Email Template Detail");
      let perm: Permission[] = [];
      screenlist.forEach(s => {
        perm.push(this.permissions.find(p => p.screenId == s.screenId));
      })
      if (perm.some(pe => pe.canView == true))
        return false;
      else
        return true;
    }
    else {
      return true;
    }
  }
  setPermission() {
    let _permission = new Permission();
    _permission.companyId = this.userDataUI.companyId;
    _permission.userRoleId = this.userDataUI.userRoleId;
    this.subscriptions.push(
      this.permissionService
        .setUserPermission(_permission)
        .subscribe((data) => {
          if (data) {
            this.getPermission();
          }
        }, error => {
          console.log(error);
        })
    )
  }
  dashboardLinkClick() {
    this.userAuthService.dashboardLinkClicked.next(true);
  }
  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.userSessionTimeStamp.unsubscribe();
  }

}
