import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAuthenticationService } from './user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadShocModuleService implements CanLoad {

  constructor(private userAuthenticationService: UserAuthenticationService, private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.userAuthenticationService.isAuthenticated()
      .then(
        (authenticated: boolean) => {
          if (authenticated == true) {
            return true;
          }
          else {
            this.router.navigate(['/home']);
            return false;
          }
        })
  }
}
