import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Permission } from '../model/permission';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {

  private getUserPermissionUrl: string;
  private setUserPermissionUrl: string

  public userPermission: Permission[];
  reloadPermission = new Subject();
  public loggedUserIsClinetAdmin: boolean = false;
  

  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.getUserPermissionUrl = config.getUserPermissionApiUrl;
    this.setUserPermissionUrl = config.setUserPermissionApiUrl;
  }

  public getUserPermissionByRoleId<T>(UserRoleId: number, CompanyId: string): Observable<T> {
    let params = new HttpParams({ fromString: 'UserRoleId=' + UserRoleId + '&CompanyId=' + CompanyId });  
    return this.httpClient.get<T>(this.getUserPermissionUrl, { params: params });
  }
  public updateUserPermission<T>(userPermissions: Permission[]): Observable<T> {
    return this.httpClient.put<T>(this.getUserPermissionUrl, userPermissions);
  }
  public setUserPermission<T>(userPermissions: Permission): Observable<T> {
    return this.httpClient.post<T>(this.setUserPermissionUrl, userPermissions);
  }
}
