<!-- Header Section Start -->
<header id="home" class="hero-area" [ngStyle]="{height:isFaqVisible?'auto':'730px'}">
  <div class="overlay"></div>
  <nav id="navbar" class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
    <div class="container">
      <a (click)="scrollTo('home')" style="cursor:pointer;" class="navbar-brand logo"><img class="img-shoc" src="../../assets/img/logo.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto w-100 justify-content-start">
          <!--<li class="nav-item">
            <a class="nav-link page-scroll" (click)="scrollTo('home')">Home</a>
          </li>-->
          <li class="nav-item">
            <a class="nav-link page-scroll" (click)="scrollTo('features')">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" (click)="scrollTo('pricing')">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" (click)="scrollTo('request')">Request</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" (click)="scrollTo('contact')">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" (click)="openFaq()">FAQ</a>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link page-scroll" [routerLink]='["/login"]'>Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link page-scroll" [routerLink]='["/register"]'>Register</a>
          </li>
          <!--<li class="nav-item">
            <a class="nav-link page-scroll">
              <span class="icon-link search">
                <img src="../../assets/img/search-icon.png" class="w-100" alt="search" />
              </span>
            </a>
          </li>-->

        </ul>
      </div>
    </div>
  </nav>
  <div class="container animate__animated animate__fadeIn" [hidden]="isFaqVisible">
    <div class="row space-100">
      <div class="col-lg-6 col-md-12 col-xs-12">
        <div class="contents">
          <h2 class="head-title">Track your assets with SHOC!</h2>
          <h3 class="h3 b-600">
            Make asset maintenance hassle-free
          </h3>
          <p>SHOC is a cost effective, cloud-based asset management software used to forecast your asset’s needs.</p>
          <div class="header-button">
            <a [routerLink]='["/register"]' class="btn btn-singin page-scroll try-shoc-btn">Register</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12 p-0 hidden-xs">
        <div class="intro-img">
          <img class="img-shoc" src="../../assets/img/banner-img.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="isFaqVisible">
    <app-faq-page></app-faq-page>
  </div>
</header>
<!-- Header Section End -->
<!-- Services Section Start -->
<div [hidden]="isFaqVisible">
  <section id="features" class="services section animate__animated animate__fadeIn">
    <div class="container">

      <div class="row">
        <!-- Start Col -->
        <div class="text-center mb-30">
          <h3 class="h3 b-600">Track your assets</h3>
          <p>SHOC (Service History On Cloud) is your perfect partner in asset management. Implementing this simple cloud-based platform will save your company time and money. SHOC enables you to care for your assets, schedule service and maintenance, and send alerts about your upcoming tasks.</p>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12 ">
          <div class="services-item text-center">
            <div class="icon">
              <img src="../../assets/img/assets-icon.png" class="w-100 img-shoc" alt="serviceManagement">
            </div>
            <h4>Asset Management</h4>
            <p>SHOC helps you maintain organized records of your valuable assets.</p>
            <span> &nbsp; </span><br />
            <span> &nbsp; </span>  <br />
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-4 col-md-6 col-xs-12 mtb-15">
          <div class="services-item text-center">
            <div class="icon">
              <img src="../../assets/img/service-icon.png" class="w-100 img-shoc" alt="serviceManagement">
            </div>
            <h4>Service Management</h4>
            <p>Cloud-based storage ensures that your service and asset maintenance history is digitally protected. Service alerts keep you informed of upcoming maintenance.</p>
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-4 col-md-6 col-xs-12">
          <div class="services-item text-center">
            <div class="icon">
              <img src="../../assets/img/user-icon.png" class="w-100 img-shoc" alt="serviceManagement">
            </div>
            <h4>Organize Users</h4>
            <p>SHOC lets you add multiple members to your account with appropriate levels of user permissions.</p>
            <span> &nbsp; </span>
          </div>
        </div>
        <!-- End Col -->

      </div>
    </div>
  </section>
  <!-- Services Section End -->
  <!--  Section Start -->
  <section class="section alter-bg animate__animated animate__fadeIn" id="services">
    <div class="container">

      <div class="row">

        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-4">
          <div class="business-item-info">
            <h3 class="h3 b-600">Choose a plan</h3>
            <p class="mb-3 mt-3">We have flexible plans for you to choose from that are categorized by the number of assets and users. Don’t see a plan you like? We can design a custom plan to suit your business needs.</p>

            <a class="btn btn-singin try-shoc-btn" (click)="scrollTo('pricing')">Choose Plan</a>
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-0 pr-5">
          <div class="business-item-img">
            <img src="../../assets/img/chooseplan.png" class="img-fluid img-shoc" alt="">
          </div>
        </div>
        <!-- End Col -->

      </div>
    </div>
  </section>
  <!-- Section End -->
  <!--  Section Start -->
  <section id="business-plan" class="animate__animated animate__fadeIn">
    <div class="container">
      <div class="row">
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-0 pr-5">
          <div class="business-item-img">
            <img src="../../assets/img/assetmanagment.png" class="img-fluid img-shoc" alt="">
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-4">
          <div class="business-item-info">
            <h3 class="h3 b-600">SHOC works with you</h3>
            <ul class="tick-list">
              <li><p>Regular asset maintenance is the key to preventing accelerated depreciation.</p></li>
              <li><p>SHOC keeps track of unplanned repairs and early asset breakdowns.</p></li>
              <li><p>SHOC will alert you to your upcoming asset services.</p></li>
              <li><p>Recording asset management is a hassle when warranties are not easily accessible. SHOC records the warranty with service history.</p></li>
              <li><p>SHOC makes reporting an easy task. See the metrics you want, not the ones you don’t. Generate reports with ease.</p></li>
            </ul>

          </div>
        </div>
        <!-- End Col -->

      </div>
    </div>
  </section>
  <!-- Business Plan Section End -->
  <!-- Section Start -->
  <section class="section alter-bg animate__animated animate__fadeIn">
    <div class="container">

      <div class="row">

        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-4">
          <div class="business-item-info">
            <h3 class="h3 b-600">“Let SHOC take care of your assets & get shocked by your growth”</h3>
            <p class="mb-3 mt-3">Designed for any business size, we have flexible plans for you to choose from. Get SHOC today!</p>

            <a class="btn btn-singin try-shoc-btn" (click)="scrollTo('pricing')">Explore</a>
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12 pl-0">
          <div class="business-item-img">
            <img src="../../assets/img/assetservice.png" class="img-fluid img-shoc" alt="">
          </div>
        </div>
        <!-- End Col -->

      </div>
    </div>
  </section>
  <!--  Section End -->
  <!--  Section Start -->
  <section id="pricing" class="section animate__animated animate__fadeIn">
    <div class="container">
      <!-- Start Row -->
      <div class="row">

        <div class="col-lg-12">
          <div class="pricing-text section-header text-center">
            <div>
              <h3 class="h3 b-600">Choose the plan that fits your business</h3>

              <div class="desc-text">
              </div>
            </div>
          </div>

        </div>
        <!-- End Row -->
        <!-- Start Row -->
        <div class="row pricing-tables" style="justify-content: center;">
          <!--  Start Col -->
          <div class="card shoc-round-card shoc-shadow" style="width: 18rem; margin: 20px;">
            <div class="card-body navbar flex-column">
              <div class="card-title">
                <div style="width:280px !important">
                  <div class="row">
                    <div class="col-sm">
                      Bronze
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">
                <div>
                  <div class="row">
                    <div class="col-sm" style="max-height: 100px;overflow: hidden;text-align: center;font-size: 0.9rem;">
                      Beginners Plan
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <!--<hr>-->
              <div>
                <span class="value">
                  <span>$</span><span>9</span><i>99</i><span style="font-size: 50%;font-weight: 400;">&nbsp;&nbsp;/ month</span>
                </span>
                <!-- <div style="text-align: center; font-size: 12px;">1 Month</div>-->
                <hr>
              </div>
              <!--<hr>-->
              <ul class="list-unstyled li-space-lg" style="font-size: 12px;">
                <li class="media">
                  <div class="media-body">100 Asset(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">1 User(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">
                    1,000 Service(s) Limit
                  </div>
                </li>
              </ul>
              <div class="button-wrapper">
                <!--<a class="btn btn-primary shoc-btn dark-font" (click)="choosePlan()">Subscribe</a>-->
              </div>
            </div>
          </div>
          <div class="card shoc-round-card shoc-shadow" style="width: 18rem; margin: 20px;">
            <div class="card-body navbar flex-column">
              <div class="card-title">
                <div style="width:280px !important">
                  <div class="row">
                    <div class="col-sm">
                      Silver
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">
                <div>
                  <div class="row">
                    <div class="col-sm" style="max-height: 100px;overflow: hidden;text-align: center;font-size: 0.9rem;">
                      Intermediate plan
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <!--<hr>-->
              <div>
                <span class="value">
                  <span>$</span><span>49</span><i>99</i><span style="font-size: 50%;font-weight: 400;">&nbsp;&nbsp;/ month</span>
                </span>
                <!--  <div style="text-align: center; font-size: 12px;">1 Month</div>-->
                <hr>
              </div>
              <!--<hr>-->
              <ul class="list-unstyled li-space-lg" style="font-size: 12px;">
                <li class="media">
                  <div class="media-body">500 Asset(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">5 User(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">
                    5,000  Service(s) Limit
                  </div>
                </li>
              </ul>
              <div class="button-wrapper">
                <!--<a class="btn btn-primary shoc-btn dark-font" (click)="choosePlan()">Subscribe</a>-->
              </div>
            </div>
          </div>
          <div class="card shoc-round-card shoc-shadow" style="width: 18rem; margin: 20px;">
            <div class="card-body navbar flex-column">
              <div class="card-title">
                <div style="width:280px !important">
                  <div class="row">
                    <div class="col-sm">
                      Gold
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">
                <div>
                  <div class="row">
                    <div class="col-sm" style="max-height: 100px;overflow: hidden;text-align: center;font-size: 0.9rem;">
                      Pro Plan
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <!--<hr>-->
              <div>
                <span class="value"><span>$</span><span>99</span><i>99</i><span style="font-size: 50%;font-weight: 400;">&nbsp;&nbsp;/ month</span></span>
                <!--  <div style="text-align: center; font-size: 12px;">1 Month</div>-->

                <hr>
              </div>
              <!--<hr>-->
              <ul class="list-unstyled li-space-lg" style="font-size: 12px;">
                <li class="media">
                  <div class="media-body">1,000 Asset(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">10 User(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">
                    10,000 Service(s) Limit
                  </div>
                </li>
              </ul>
              <div class="button-wrapper">
                <!--<a class="btn btn-primary shoc-btn dark-font" (click)="choosePlan()">Subscribe</a>-->
              </div>
            </div>
          </div>
          <div class="card shoc-round-card shoc-shadow" style="width: 18rem; margin: 20px;">
            <div class="card-body navbar flex-column">
              <div class="card-title">
                <div style="width:280px !important">
                  <div class="row">
                    <div class="col-sm">
                      Custom Plan
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">
                <div>
                  <div class="row">
                    <div class="col-sm" style="max-height: 100px;overflow: hidden;text-align: center;font-size: 0.9rem;padding-top: 1.5rem;">
                      <!--Custom Plan-->
                      <hr style="width: 8rem;">
                    </div>
                  </div>
                </div>
              </div>
              <!--<hr>-->
              <div>
                <span class="value" style="font-size: 20px; padding-left: 46px; padding-right: 48px;">
                  Design a custom plan to suit your business needs
                </span>
                <!--  <div style="text-align: center; font-size: 12px;">30 Day(s)</div>-->
                <hr style="width: 8rem;">
              </div>
              <!--<hr>-->
              <ul class="list-unstyled li-space-lg" style="font-size: 12px;">
                <li class="media">
                  <div class="media-body">Unlimited Asset(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">Unlimited User(s)</div>
                </li>
                <li class="media">
                  <div class="media-body">
                    Unlimited Service(s)
                  </div>
                </li>
              </ul>
              <div class="button-wrapper">
                <!--        <a class="btn btn-primary shoc-btn dark-font" (click)="choosePlan()">Subscribe</a>-->
              </div>
            </div>
          </div>

          <!--  End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
  </section>
  <!--  Section End -->
  <!--  Section Start -->
  <section id="request" class="section alter-bg animate__animated animate__fadeIn">
    <!-- Container Starts -->
    <div class="container">
      <!-- Start Row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="contact-text section-header text-center">
            <div>
              <h3 class="h3 b-600">Let our support team get you acquainted with SHOC</h3>
              <div class="desc-text">
                <p class="mb-3 mt-3">Sign up here and we will get in touch with you to explain the value of implementing SHOC for your business.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- End Row -->
      <!-- Start Row -->
      <div class="row">
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12">
          <form #contactForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" id="name" name="name" ngModel placeholder="Name" required #name="ngModel">
                  <div class="help-block with-errors" *ngIf="!name.valid && name.touched">Enter your name</div>
                </div>
              </div>
              <div class="col-md-6">

                <div class="form-group">
                  <input type="email" class="form-control" id="email" name="email" ngModel placeholder="Email" required email #email="ngModel">
                  <div class="help-block with-errors" *ngIf="!email.valid && email.touched">Enter a valid email</div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <dx-text-box mode="phone" id="phone" name="phone" class="form-control" ngModel required #phone="ngModel" mask="000-000-0000"
                               maskInvalidMessage="Enter valid phone number"
                               [useMaskedValue]="true">
                  </dx-text-box>
                  <!--  <input type="text" placeholder="Phone" id="phone" class="form-control" name="phone" ngModel required #phone="ngModel">-->
                  <div class="help-block with-errors" *ngIf="!phone.valid && phone.touched">Enter phone number</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select class="form-control" id="plan" name="plan" ngModel>
                    <option class="select-option" value="" disabled="" selected="">Interested in...</option>
                    <option value="Bronze">Bronze</option>
                    <option value="Silver">Silver</option>
                    <option value="Gold">Gold</option>
                    <option value="Custom">Custom</option>
                    <!--<option value="8">Summit Industrial Construction</option>
                    <option value="100">Demo Plan</option>
                    <option value="105">SIC</option>
                    <option value="107">Custom Plan A</option>
                    <option value="108">Custom Plan B</option>-->
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group checkbox">
                  <input type="checkbox" id="rterms" value="Agreed-to-Terms" name="rterms" ngModel required #rterms="ngModel">&nbsp;I agree with S H O C's stated <a href="#">Privacy Policy</a> and <a href="#">Terms &amp; Conditions</a>
                  <!--<div class="help-block with-errors" *ngIf="!rterms.valid && rterms.touched">Check to agree with.</div>-->
                </div>

                <div class="submit-button">
                  <button typeof="submit" class="btn btn-singin try-shoc-btn" [disabled]="!contactForm.valid">Submit</button>
                  <!--<div id="msgSubmit" class="h3 hidden"></div>
                  <div class="clearfix"></div>-->
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-1">

        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-4 col-md-12">
          <div class="contact-img">
            <img src="../../assets/img/contact-bg.png" class="img-fluid img-shoc" alt="">
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-1">
        </div>
        <!-- End Col -->

      </div>
      <!-- End Row -->
    </div>
  </section>
  <!--  Section End -->
  <!--  Section Start -->
  <section class="section" hidden>
    <div class="container">
      <div class="showcase-text section-header text-center">
        <div>
          <h3 class="h3 b-600">Testimonials</h3>
          <div class="desc-text">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</p>
            <p>eiusmod tempor incididunt ut labore et dolore.</p>
          </div>
        </div>
      </div>
    </div>
    <div id="testimonial" class="section">
      <div class="container right-position">
        <!-- Start Row -->
        <!--<div class="row">

          <div class="col-md-12 col-sm-12">
            <div class="video-promo-content text-center"></div>
          </div>
        </div>-->
        <!-- End Row -->
        <!-- Start Row -->
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <!--<ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>-->
          <div class="carousel-inner text-center">
            <div class="carousel-item active">
              <div class="item d-block w-100">
                <div class="testimonial-item">
                  <!--<div class="client-thumb">
                    <img src="../../assets/img/" alt="">
                  </div>-->
                  <div class="content-inner">
                    <p class="description">Managing assets has been a mess throughout the past few years in our company. SHOC became our life saver in that matter..</p>
                    <div class="author-info">
                      <h5>Arun Raj</h5>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="item d-block w-100">
                <div class="testimonial-item">
                  <!--<div class="client-thumb">
                    <img src="../../assets/img/01.png" alt="">
                  </div>-->
                  <div class="content-inner">
                    <p class="description">Alert system and report generation in SHOC helped us a lot and saves a lot of time and effort in asset management.</p>
                    <div class="author-info">
                      <h5>Bijoy George.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="item d-block w-100">
                <div class="testimonial-item">
                  <!--<div class="client-thumb">
                    <img src="../../assets/img/01.png" alt="">
                  </div>-->
                  <div class="content-inner">
                    <p class="description">We started using SHOC in the silver plan. But it has been our best companion in asset management since then and we upgraded our plan to platinum..</p>
                    <div class="author-info">
                      <h5>Rahul Raj </h5>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!--<div class="row justify-content-center testimonial-area">
          <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12 pr-20 pl-20" style="overflow: hidden;padding-bottom: 60px">
            <div id="client-testimonial" class="text-center owl-carousel">
              <div class="item">
                <div class="testimonial-item">
                  <div class="content-inner">
                    <p class="description">Managing assets has been a mess throughout the past few years in our company. SHOC became our life saver in that matter..</p>
                    <div class="author-info">
                      <h5>Arun Raj</h5>
                    </div>
                  </div>
                  <div class="client-thumb">
                    <img src="../../assets/img/" alt="">
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-item">
                  <div class="content-inner">
                    <p class="description">Alert system and report generation in SHOC helped us a lot and saves a lot of time and effort in asset management.</p>
                    <div class="author-info">
                      <h5>Bijoy George.</h5>
                    </div>
                  </div>
                  <div class="client-thumb">
                    <img src="../../assets/img/01.png" alt="">
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimonial-item">
                  <div class="content-inner">
                    <p class="description">We started using SHOC in the silver plan. But it has been our best companion in asset management since then and we upgraded our plan to platinum..</p>
                    <div class="author-info">
                      <h5>Rahul Raj </h5>
                    </div>
                  </div>
                  <div class="client-thumb">
                    <img src="../../assets/img/01.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <!-- End Row -->
      </div>
    </div>
  </section>
  <!--  Section End -->
  <!--  Section Start -->
  <section class="section alter-bg animate__animated animate__fadeIn">
    <div class="container">
      <div class="showcase-text section-header text-center" style="margin-bottom:0px;">
      </div>
    </div>
  </section>
  <!--  Section End -->
  <!--  Section Start -->
  <section id="contact" class="section animate__animated animate__fadeIn">
    <div class="container">
      <div class="showcase-text section-header text-center">
        <div>
          <h3 class="h3 b-600">Contact Information</h3>

        </div>

      </div>

      <div class="row">
        <!-- Start Col -->
        <div class="col-lg-6 col-md-12">
          <div class="business-item-info">
            <address>
              Contact us
              <dl>
                <dt>
                  <span class="icon icon-location"></span>Vione Technologies LLC,<br />4801 Woodway Drive,<br />Suite 300, Office# 333,<br /> East Houston, Texas, 77056, USA
                </dt>
                <dt>
                  <span class="icon icon-telephone"></span> <a class="turquoise" href="tel:+17132922215" style="color: #ffc107 !important">(713) 292-2215</a>
                </dt>
                <dt>
                  <span class="icon icon-mail"></span><a class="turquoise" href="mailto:admin@shocapp.com" style="color: #ffc107 !important">admin@shocapp.com</a>
                </dt>
              </dl>
            </address>
          </div>
        </div>
        <!-- End Col -->
        <div class="col-lg-6 col-md-12">
          <div class="business-item-img">
            <div class="map-responsives contact-bg">
              <iframe width="100%" height="300px" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.485856810417!2d-95.46235958489147!3d29.763589681985486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c1d71078f0d5%3A0xe9785a03e12ad4d9!2sVione%20Technologies%20LLC!5e0!3m2!1sen!2sin!4v1578294887331!5m2!1sen!2sin" allowfullscreen></iframe>
            </div>
          </div>
        </div>

      </div>

    </div>

  </section>
  <!--  Section End -->
  <!--  Section Start -->
</div>
<footer>
  <!-- Footer Area Start -->
  <section class="footer">
    <div class="container">
      <!-- Start Row -->
      <div class="row">

        <!-- Start Col -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <h3 class="block-title">About SHOC</h3>
            <p>{{version}}</p>
            <p>A cost-effective asset management solution to track the condition and forecast the needs of your assets.</p>
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <h3 class="block-title">Important Links</h3>
            <a [routerLink]='["/register"]'>Register</a>  <br />
            <a (click)="openFaq()" style="cursor:pointer">FAQ</a>
          </div>
        </div>
        <!-- End Col -->
        <!-- Start Col -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <h3 class="block-title">Social Media</h3>
            <a href="https://www.facebook.com/SHOC-105571561367903" class="social-icon fb img-shoc" target="_blank"><img src="../../assets/img/ic-fb.png" alt=""></a>
            <a href="https://twitter.com/SHOCSoftware" class="social-icon twitter img-shoc" target="_blank"><img src="../../assets/img/ic_twitter.png" alt=""></a>
            <!-- <a href="https://www.instagram.com/accounts/login/?hl=en" class="social-icon insta" target="_blank"><img src="../../assets/img/ic-insta.png" alt=""></a>-->
            <a href="https://www.linkedin.com/showcase/shoc-assetmanagement" class="social-icon linked img-shoc" target="_blank"><img src="../../assets/img/ic-linked.png" alt=""></a>
            <a href="https://www.google.com/search?authuser=2&_ga=2.42958051.793086889.1612375306-1081810677.1612375306&q=Vione+Technologies+LLC&ludocid=16823295380751570137&lsig=AB86z5X9Yia9rcR2kMaqEjXZLZK6" class="social-icon gplus img-shoc" target="_blank"><img src="../../assets/img/ic-gplus.png" alt=""></a>
          </div>
        </div>
      </div>
      <!-- End Row -->
    </div>
    <!-- Copyright Start  -->

    <div class="copyright">
      <div class="container">
        <!-- Star Row -->
        <div class="row">
          <div class="col-md-12">
            <div class="site-info text-center">
              <p>Copyright © S H O C - Service History on Cloud by Vione Technologies LLC</p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- Copyright End -->
  </section>
  <!-- Footer area End -->

</footer>
<!--  Section End -->
<!-- Go To Top Link -->
<a id="back2top" (click)="scrollTo('home')" class="back-to-top try-shoc-btn">
  <i class="lni-chevron-up"></i>
</a>
<!-- Preloader -->
<!--<div id="preloader">
  <div class="loader" id="loader-1"></div>
</div>-->
<!-- End Preloader -->

<ng-template #tryShocModal let-modal>
  <div class="try-shoc shoc-bg">
    <h5 class="modal-title try-shoc-btn">TRY SHOC FREE FOR 30 DAYS</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeTryShocModal('close')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h6 style="font-size: 23px;">Ready to get started with the free trial?</h6>
    </div>
    <div class="try-shoc-btn">
      <button type="button" class="btn btn-primary" style="margin:10px;" (click)="closeTryShocModal('register')">Register Now</button>
    </div>
    <hr style="border-color:black;" />
    <p style="color:black;">Already have an account?</p>
    <div class="try-shoc-btn">
      <button type="button" class="btn btn-dark" style="margin:10px;text-transform: unset;" (click)="closeTryShocModal('login')">Login</button>
    </div>
  </div>
</ng-template>
