import { Resource } from './resource';

export class Permission extends Resource {
  no: number;
  userPermissionId: number;
  userRoleId: number;
  screenId: number;
  screenName: string;
  companyId: string;
  canEdit: boolean;
  canAdd: boolean;
  canView: boolean;
  canDelete: boolean;
  canPrint: boolean;
}
