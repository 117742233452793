import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Configuration } from './app.constants';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokenInterceptor } from './services/auth/tokeninterceptor.service';
import { LoadingSpinnerComponent } from './helper/loading-spinner/loading-spinner.component';
import { HomeComponent } from './home/home.component';
import { NgIdleModule } from '@ng-idle/core';
import { VerifyUserComponent } from './register/verify-user/verify-user.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { FaqPageComponent } from './home/faq-page/faq-page.component';
import { ScrollSpyDirective } from './helper/directives/scroll-spy.directive';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxFormModule } from 'devextreme-angular/ui/form'
import { ConfirmModalComponent } from './helper/confirm-modal/confirm-modal.component';
import { SubscribePlanComponent } from './setup/subscribe-plan/subscribe-plan.component';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';

@NgModule({
  declarations: [
    AppComponent,
    NavHeaderComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    VerifyUserComponent,
    LoadingSpinnerComponent,
    FaqPageComponent,
    ScrollSpyDirective,
    ConfirmModalComponent,
    SubscribePlanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxSpinnerModule,
    DxTextBoxModule,
    DxFormModule,
    DxTabPanelModule,
    ToastrModule.forRoot(),
    NgIdleModule.forRoot()
  ],
  providers: [Configuration, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  entryComponents: [ConfirmModalComponent, SubscribePlanComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
