import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { UserAuthenticationService } from './services/user-authentication.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { CompanyDetails } from './model/company';
import { CompanyService } from './services/company.service';
import { LoginHistoryService } from './services/login-history.service';
import { LoginHistory } from './model/loginhistory';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ClientApp';

  isLoggedIn: boolean = false;
  timedOut = false;

  constructor(private userAuthService: UserAuthenticationService, private idle: Idle, private router: Router, private companyService: CompanyService, private loginHistoryService: LoginHistoryService) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(500);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.reset();
    });
    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.reset();
      //For hiding the banner and clear localstorage
      //sessionStorage.clear();
      //this.cookie.deleteAll();
      //this.userAuthService.loggedInChange.next(false);
      //this.router.navigate(['/home']);
      this.logout();
    });

    this.reset();
  }

  ngOnInit() {
    //localStorage.clear();
    this.userAuthService.loggedInChange.subscribe(change => {
      this.isLoggedIn = change;
      if (this.isLoggedIn == true) {
        this.companyService.getAllCompany().subscribe((company: CompanyDetails[]) => {
          if (company) {
            //let companyId = JSON.parse(this.cookie.get("UserData")).companyId;
            let companyId = JSON.parse(sessionStorage.getItem("UserData")).companyId;
            let currentCompany: CompanyDetails = company.find(c => c.companyId == companyId);
            this.idle.setIdle(currentCompany.sessionTimeout);
          }
        })
      }
    })
  }



  unload(event) {
   // this.logout();
    return true;
  }
  logout() {
    let userData = sessionStorage.getItem("UserData");
    if (userData) {
      let logindata: LoginHistory = JSON.parse(sessionStorage.getItem("LoginData"));
      logindata.description = "Session Timeout";
      this.loginHistoryService
        .updateLoginHistory(logindata)
        .subscribe(data => {
          //this.cookie.deleteAll();
          sessionStorage.clear();
          this.userAuthService.loggedInChange.next(false);
          this.router.navigate(['/home']);

        },
          error => {
            //this.cookie.deleteAll();
            sessionStorage.clear();
            this.userAuthService.loggedInChange.next(false);
            this.router.navigate(['/home']);
          })
    }
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
}
