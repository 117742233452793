import { Resource } from './resource';
import { CompanyDetails } from './company';


export class UserDetails extends Resource{
  no: number;
  userId: string;
  companyId: string;
  userName: string;
  userDescription: string;
  userRoleId: number;
  userEmail: string;
  userPassword: string;
  userPhoneNumber: string;
  isActive: boolean;
  active: string;
  isClientAdmin: boolean;
  company: CompanyDetails;
}
