import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ScreenListService } from './screen-list.service';
import { UserPermissionService } from './user-permission.service';
import { Permission } from '../model/permission';
import { ScreenList } from '../model/ScreenList';
import { UserDataUI } from '../model/userDataUI';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticatedService implements CanActivate {
  screenPaths = [
    { path: 'company-management', screen: 'Company List' },
    { path: 'company-detail', screen: 'Company Detail' },
    { path: 'assets', screen: 'Asset List' },
    { path: 'service', screen: 'Service' },
    { path: 'service-history', screen: 'Service History' },
    { path: 'importexport', screen: 'Asset List' },
    { path: 'user-management', screen: 'User List' },
    { path: 'plans', screen: 'Plans' },
    { path: 'accounts', screen: 'Accounts' },
    { path: 'assign-custom-plan', screen: 'Custom Plan List' },
    { path: 'service-alerts', screen: 'Service Alerts' },
    { path: 'company-detail', screen: 'Company Detail' },
    { path: 'lookup-settings', screen: 'Lookup Settings' },
    { path: 'login-history', screen: 'Login History' },
    { path: 'email-template', screen: 'Email Template List' },
    { path: 'permissions', screen: 'Permissions' },
    { path: 'rename-custom', screen: 'Rename Custom' }];
  userDataUI: UserDataUI = null;

  constructor(private router: Router, private screenListService: ScreenListService, private permissionService: UserPermissionService) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (route.url[0].path == 'home' || route.url[0].path == 'login' || route.url[0].path == 'register') {
      return this.isAuthenticated()
        .then(
          (authenticated: boolean) => {
            if (authenticated == true) {
              return true;
            }
            else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          })
    }
    else {
      //route.url.forEach(x => console.log(x.path));
      return this.isScreenAuthenticated(route.url[0].path)
        .then(
          (screenAccessible: boolean) => {
            if (screenAccessible == true) {
              return true;
            }
            else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          })
    }
  }

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        let user = sessionStorage.getItem('UserData');
        if (user) {
          //this.loggedIn = true;
          resolve(false);
        }
        else {
          //this.loggedIn = false;
          resolve(true);
        }
      }
    );
    return promise;
  }

  isScreenAuthenticated(screen: string) {
    const promise = new Promise(
      (resolve, reject) => {
        this.userDataUI = JSON.parse(sessionStorage.getItem("UserData"));
        let permission: Permission = null;
        if (this.screenListService.screenList) {
          //ScreenCheck
          let currentScreen: ScreenList = this.screenListService.screenList.find(x => this.screenPaths.find(s => s.path == screen) && this.screenPaths.find(s => s.path == screen).screen == x.screenName);
          if (this.permissionService.userPermission) {
            permission = this.permissionService.userPermission.find(y => y.screenId == currentScreen.screenId);
          }
        }
        if (permission) {
          if (permission.canView == true) {
            if (this.userDataUI.companyId != this.userDataUI.masterCompanyId && screen == "company-management") {
              resolve(false);
            }
            else if (this.userDataUI.companyId == this.userDataUI.masterCompanyId && (screen == "assets" || screen == "service" || screen == "service-history" || screen == "importexport" || screen == "accounts" || screen == "service-alerts" || screen == "login-history" || screen == "rename-custom")) {
              resolve(false);
            }
            else {
              resolve(true);
            }
          }
          else {
            resolve(false);
          }
        }
        else {
          this.screenListService
            .getAllScreens<ScreenList[]>()
            .subscribe((data: ScreenList[]) => {
              if (data) {
                //ScreenCheck
                let screenList = data;
                let currentScreen: ScreenList = screenList.find(x => this.screenPaths.find(s => s.path == screen) && this.screenPaths.find(s => s.path == screen).screen == x.screenName);
                this.permissionService
                  .getUserPermissionByRoleId(this.userDataUI.userRoleId, this.userDataUI.companyId)
                  .subscribe((per: any[]) => {
                    if (per != null && per != undefined && per.length > 0) {
                      let perm: Permission[] = per;
                      let permission: Permission = perm.find(y => y.screenId == currentScreen.screenId);
                      if (permission.canView == true) {
                        if (this.userDataUI.companyId != this.userDataUI.masterCompanyId && (screen == "company-management" || screen == "email-template" || screen =="assign-custom-plan")) {
                          resolve(false);
                        }
                        else if (this.userDataUI.companyId == this.userDataUI.masterCompanyId && (screen == "assets" || screen == "service" || screen == "service-history" || screen == "importexport" || screen == "accounts" || screen == "service-alerts" || screen == "login-history" || screen == "rename-custom")) {
                          resolve(false);
                        }
                        else {
                          resolve(true);
                        }
                      }
                      else {
                        resolve(false);
                      }
                    }
                    else {
                      resolve(false);
                    }
                  },
                    (error) => {
                      console.log(error);
                      resolve(false);
                    }
                  )
              }
            },
              (error) => {
                console.log(error);
                resolve(false);
              })
        }
      }
    );
    return promise;
  }
}
