import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../app.constants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserDetails } from '../model/userdetail';


@Injectable({
  providedIn: 'root'
})
export class UserdetailsService {
  private userDetailsUrl: string;
  private validateUserUrl: string;
  private updateUserDetailsUrl: string;
  private verifyConnectionUrl: string;
  private userSessionUrl: string;
  private userAuthUrl: string;

  constructor(private httpClient: HttpClient, private config: Configuration) {

    this.userDetailsUrl = this.config.userDetailsApiUrl;
    this.validateUserUrl = this.config.validateUserLoginApiUrl;
    this.userAuthUrl = this.config.userAuthApiUrl;
    this.updateUserDetailsUrl = this.config.updateUserDetailsApiUrl;
    this.verifyConnectionUrl = this.config.verifyConnectionApiUrl;
    this.userSessionUrl = this.config.userSessionApiUrl;
  }

  public getUserDetails<T>(): Observable<T> {
    return this.httpClient.get<T>(this.userDetailsUrl);
  }
  public getUserDetailsByCompanyId<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.userDetailsUrl + "/" + companyId);
  }
  public verifyConnection<T>(id: number, code: string): Observable<T> {
    let params = new HttpParams({ fromString: 'id=' + id + '&code=' + code });
    return this.httpClient.get<T>(this.verifyConnectionUrl, { params: params });
  }

  public validateUser<T>(user: any): Observable<T> {
  //  let params = new HttpParams({ fromString: 'email=' + email + '&password=' + password });
    return this.httpClient.post<T>(this.validateUserUrl, user);
  }


  public getUserdataUI<T>(user: UserDetails): Observable<T> {
    //  let params = new HttpParams({ fromString: 'email=' + email + '&password=' + password });
    return this.httpClient.post<T>(this.userAuthUrl, user);
  }

  public updateUserDetails<T>(userDetails: UserDetails): Observable<T> {
    return this.httpClient.put<T>(this.updateUserDetailsUrl, userDetails);
  }

  public deleteUserDetails<T>(userDetails: UserDetails): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: userDetails,
    };
    return this.httpClient.delete<T>(this.updateUserDetailsUrl, options);
  }

  public getUserDetailsById<T>(UserId: string): Observable<T> {
    return this.httpClient.get<T>(this.updateUserDetailsUrl + "/" + UserId);
  }
  public addUser<T>(user: any): Observable<T> {
    return this.httpClient.post<T>(this.updateUserDetailsUrl, user);
  }
  public updateUserSession<T>(user: any): Observable<T> {
    return this.httpClient.post<T>(this.userSessionUrl, user);
  }

}
