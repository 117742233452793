import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PaymentIntentService } from 'src/app/services/payment-intent.service';
import { PaymentIntent } from 'src/app/model/paymentIntent';
import validationEngine from 'devextreme/ui/validation_engine';
import { LicenseHistory } from 'src/app/model/licensehistory';
import { LicenseService } from 'src/app/services/license.service';
import { Router } from '@angular/router';
import { CustomerCreate } from 'src/app/model/customerCreate';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDataUI } from 'src/app/model/userDataUI';
import { ToastrService } from 'ngx-toastr';
declare var Stripe;

@Component({
  selector: 'app-subscribe-plan',
  templateUrl: './subscribe-plan.component.html',
  styleUrls: ['./subscribe-plan.component.css']
})
export class SubscribePlanComponent implements OnInit, AfterViewInit {

  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  @Input() public userPlan;
  @Input() public addPayments;
  @Input() public defaultPayment;
  @Input() public isSubscriptionActive;
  @Input() public paymentType;
  @Input() public currentCompany;
  cardDetails = new CardDetails;
  bankDetails = new BankDetails;
  stripe;
  modalTitle;
  plaidClient;
  card;
  cardErrors;

  subscription: Subscription[] = [];
  userDataUI: UserDataUI = null;
  isDisabled: boolean = false;
  token;
  userPaymentMethods: any;
  constructor(public modal: NgbActiveModal, private intentService: PaymentIntentService, private licenseService: LicenseService,
    private router: Router, private spinnerService: NgxSpinnerService, private toastr: ToastrService) {

  }
  ngOnInit() {
    this.userDataUI = JSON.parse(sessionStorage.getItem("UserData"));
    this.modalTitle = 'Payment Details';
    if (this.addPayments) {
      this.modalTitle = 'Add Payment methods';
    }
    else {
      this.getPaymentMethods();
    }

  }
  ngAfterViewInit() {
    this.stripe = Stripe(this.intentService.stripe_pk_Key);

    if (!this.defaultPayment && (this.paymentType == 'Card' || this.paymentType == '')) {
      var style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif'
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          fontFamily: '"Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif'
        }
      };
      if (this.paymentType != 'Bank') {
        const elements = this.stripe.elements();
        this.card = elements.create('card', { style: style });
        this.card.mount(this.cardElement.nativeElement);
        this.card.addEventListener('change', ({ error }) => {
          // this.cardErrors = error && error.message
          if (error) {
            //notify({ message: error.message, width: 300, position: 'top right' }, 'warning', 5000);
            this.toastr.warning(error.message);
          }
        });
      }

    }

  }

  getPaymentMethods() {
    this.subscription.push(
      this.intentService
        .getAllPaymentMethods<any>(this.currentCompany.stripeCustomerCode)
        .subscribe((data: any) => {
          this.userPaymentMethods = data.find(x => x.isDefault == true);
          // console.log("USER PAYMENT METHODS", this.userPaymentMethods);
        }, error => {
          console.log(error);
        })
    )
  }

  submitPayment(cardDetValidation, isCard: boolean) {

    let validationResult = validationEngine.validateGroup(cardDetValidation);
    if (validationResult.isValid) {
      this.spinnerService.show();
      if (isCard) {
        this.paywithCard();
      }
      else {
        this.paywithBank();
      }
    }

  }
  paywithCard() {
    this.stripe.createToken(this.card).then((result: any) => {
      if (result.error) {
        this.spinnerService.hide();
        //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
        this.toastr.warning(result.error.message);
      }
      else {
        this.getToken(false, null);
      }
    });
  }
  paywithBank() {
    let accountParmas = {
      country: 'US', currency: 'USD',
      routing_number: this.bankDetails.routingNumber,
      account_number: this.bankDetails.accountNumber,
      account_holder_name: this.bankDetails.accountHolderName,
      account_holder_type: 'company'
    }
    this.stripe.createToken('bank_account', accountParmas).then((result: any) => {
      if (result.error) {
        this.spinnerService.hide();
        //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
        this.toastr.warning(result.error.message);
      }
      else {
        this.getToken(true, result.token);
      }
    });
  }
  getToken(isBank: boolean, token: any) {
    if (this.userPlan) {
      let customerReq = new CustomerCreate();
      customerReq.email = this.userPlan.email;
      customerReq.licenseId = this.userPlan.licenseId;
      customerReq.companyId = this.userPlan.companyId;
      customerReq.isCard = true;
      if (isBank) { customerReq.isCard = false; customerReq.paymentMethod = token.id; }
      this.subscription.push(
        this.intentService
          .createIntent<CustomerCreate>(customerReq)
          .subscribe((data: any) => {
            if (isBank) {
              //notify({ message: 'Payment completed!!', width: 300, position: 'top right' }, 'success', 2000);
              this.toastr.success('Payment completed.');
              this.addUserPlan();
              this.isDisabled = false;
              this.modal.close();
            } else {
              this.token = data.clientsecret;
              this.confirmPayment();
            }
          }, ((error: any) => {
            this.spinnerService.hide();
            if (isBank) {
              //notify({ message: 'Please complete verification to proceed!!', width: 300, position: 'top right' }, 'warning', 2000);
              this.toastr.warning('Please complete verification to proceed.');
            } else {
              //notify({ message: 'Unable to process the payment now!!', width: 300, position: 'top right' }, 'warning', 2000);
              this.toastr.warning('Unable to process the payment now.');
            }
            this.isDisabled = true;
            this.modal.dismiss();
          }))
      )
    }
    else {
      //notify({ message: 'Unable to process the payment now!!', width: 300, position: 'top right' }, 'warning', 2000);
      this.toastr.warning('Unable to process the payment now.');
      this.isDisabled = true;
    }
  }


  confirmPayment() {
    if (this.token) {
      this.isDisabled = true;
      this.stripe.confirmCardPayment(this.token,
        {
          payment_method: { card: this.card }

        }).then((result: any) => {
          //console.log(result);
          if (result.error) {
            //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
            this.toastr.warning(result.error.message);
            this.isDisabled = false;
            this.spinnerService.hide();
            this.modal.dismiss();
          }
          else {
            // this.addUserPlan();
            this.confirmCardIntent();
            this.isDisabled = false;
          }
        });
    }
  }

  offSessionPayment() {
    let customerReq = new CustomerCreate();
    customerReq.email = this.userPlan.email;
    customerReq.licenseId = this.userPlan.licenseId;
    customerReq.companyId = this.userPlan.companyId;
    customerReq.paymentMethod = this.defaultPayment;
    // customerReq.isCard = true;
    let isBank = false;
    this.spinnerService.show();
    this.subscription.push(
      this.intentService
        .paymentOffSession<CustomerCreate>(customerReq)
        .subscribe((data: any) => {
          if (data.isBank) {
            //notify({ message: 'Payment completed!!', width: 300, position: 'top right' }, 'success', 2000);
            this.toastr.success('Payment completed.');
            this.addUserPlan();

            this.isDisabled = false;
            this.modal.close();
          } else {
            this.token = data.clientsecret;
            this.confirmOffSessionPayment();
          }
        }, ((error: any) => {
          this.spinnerService.hide();
          // if (isBank) { notify({ message: 'Please complete verification to proceed!!', width: 300, position: 'top right' }, 'warning', 2000); } else {
          if (error.error) {
            //notify({ message: error.error, width: 300, position: 'top right' }, 'warning', 4000);
            this.toastr.warning(error.error);}
          else {
            //notify({ message: 'Unable to process the payment now!!', width: 300, position: 'top right' }, 'warning', 4000);
            this.toastr.warning('Unable to process the payment now.'); }
          // }
          this.isDisabled = true;
          this.modal.dismiss();
        }))
    )
  }
  confirmOffSessionPayment() {
    if (this.token) {
      this.isDisabled = true;
      this.stripe.confirmCardPayment(this.token).then((result: any) => {
        if (result.error) {
          //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
          this.toastr.warning(result.error.message);
          this.isDisabled = false;
          this.spinnerService.hide();
          this.modal.dismiss();
        }
        else {
          this.confirmCardIntent();
          //this.addUserPlan();         
          this.isDisabled = false;
        }
      });
    }
  }
  addUserPlan() {
    if (this.userPlan) {
      let licenseHistory = new LicenseHistory();
      licenseHistory.licenseId = this.userPlan.licenseId;
      licenseHistory.companyId = this.userPlan.companyId;
      licenseHistory.createdBy = this.userDataUI.userId;
      licenseHistory.modifiedBy = this.userDataUI.userId;
      this.subscription.push(
        this.licenseService
          .addLicenseHistory(licenseHistory)
          .subscribe((data: LicenseHistory) => {
            if (data) {
              //notify({ message: 'Successfully subscribed to ' + data.license.licenseName, width: 300, position: 'top right' }, 'success', 5000);
              this.toastr.success('Successfully subscribed to ' + data.license.licenseName+'.');;
              let userData: UserDataUI = JSON.parse(sessionStorage.getItem("UserData"));
              userData.expire = data.licenseEndDate;
              userData.planId = data.licenseId;
              //this.cookie.delete("UserData");
              //this.cookie.set("UserData", JSON.stringify(userData));
              sessionStorage.removeItem("UserData");
              sessionStorage.setItem("UserData", JSON.stringify(userData));
              this.spinnerService.hide();
              this.licenseService.planChange.next(false);
              this.router.navigate(['/dashboard']);
              this.modal.dismiss();
            }
          },
            error => {
              console.log(error);
              this.spinnerService.hide();
              this.modal.dismiss();
              //notify({ message: 'An error occured', width: 300, position: 'top right' }, 'error', 5000);
              this.toastr.error('An error occured.');
            })
      )
    }
  }
  createSubscription() {
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        email: this.userPlan.email
      },
    }).then((result: any) => {
      //console.log(result);
      if (result.error) {
        this.spinnerService.hide();
        //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
        this.toastr.warning(result.error.message);
      }
      else {
        this.stripePaymentHandler(result);
      }
    });

  }
  stripePaymentHandler(result) {
    if (result.error) {
      this.spinnerService.hide();
      //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
      this.toastr.warning(result.error.message);
    }
    else {
      let customerReq = new CustomerCreate();
      customerReq.email = this.userPlan.email;
      customerReq.paymentMethod = result.paymentMethod.id;
      customerReq.licenseId = this.userPlan.licenseId;
      customerReq.companyId = this.userPlan.companyId;


      this.subscription.push(
        this.intentService
          .createCustomer<CustomerCreate>(customerReq)
          .subscribe((data: any) => {
            //console.log("Sucess", data);
            this.handleSubscription(data);
          }, error => {
            this.spinnerService.hide();
            //notify({ message: 'Unable to process the payment now!!', width: 300, position: 'top right' }, 'warning', 2000);
            this.toastr.warning('Unable to process the payment now.');
          })
      )
    }
  }
  handleSubscription(subscription) {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {

      const { client_secret, status } = payment_intent;

      if (status === 'requires_action') {
        this.stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            //notify({ message: 'Unable to process the payment now!!', width: 300, position: 'top right' }, 'warning', 2000);
            this.toastr.warning('Unable to process the payment now.');
            this.spinnerService.hide();
          } else {
            this.addUserPlan();
          }
        });
      } else {
        this.addUserPlan();
      }
    }
  }

  addCardPaymentMethod(cardDetValidation) {
    let validationResult = validationEngine.validateGroup(cardDetValidation);
    this.spinnerService.show();
    if (validationResult.isValid && this.addPayments) {
      this.stripe.createToken(this.card, { name: this.cardDetails.nameOnCard }).then((result: any) => {
        if (result.error) {
          this.spinnerService.hide();
          //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
          this.toastr.warning(result.error.message);
        }
        else {
          this.addCardorBank(result.token, true);
        }
      });
    }
    else {
      //notify({ message: 'Invalid details', width: 300, position: 'top right' }, 'warning', 5000);
      this.toastr.warning( 'Invalid details.');
      this.spinnerService.hide();
    }
  }
  addCardorBank(token, isCard) {

    let req = new PaymentIntent();
    req.token = token.id;
    req.customerId = this.addPayments.customerId;
    req.isCard = isCard;
    this.subscription.push(
      this.intentService
        .paymentMethods<PaymentIntent>(req)
        .subscribe((data: any) => {
          //console.log(data);
          this.spinnerService.hide();
          this.modal.close();
        }, error => {
          this.spinnerService.hide();
          //notify({ message: error.error, width: 300, position: 'top right' }, 'warning', 2000);
          this.toastr.warning(error.error);
          this.modal.dismiss();
        })
    )
  }
  addBankPaymentMethod(bankDetValidation) {
    let validationResult = validationEngine.validateGroup(bankDetValidation);
    this.spinnerService.show();
    if (validationResult.isValid && this.addPayments) {
      if (this.bankDetails.accountNumber == this.bankDetails.confirmAccountNumber) {
        let accountParmas = {
          country: 'US', currency: 'USD',
          routing_number: this.bankDetails.routingNumber,
          account_number: this.bankDetails.accountNumber,
          account_holder_name: this.bankDetails.accountHolderName,
          account_holder_type: 'company'
        }
        this.stripe.createToken('bank_account', accountParmas).then((result: any) => {
          if (result.error) {
            this.spinnerService.hide();
            //notify({ message: result.error.message, width: 300, position: 'top right' }, 'warning', 5000);
            this.toastr.warning(result.error.message);
          }
          else {
            this.addCardorBank(result.token, false);
          }
        });
      }
      else {
        //notify({ message: 'Account number missmatch', width: 300, position: 'top right' }, 'warning', 5000);
        this.toastr.warning('Account number mismatch.');
        this.spinnerService.hide();
      }
    }
    else {
      this.spinnerService.hide();
      //notify({ message: 'Invalid details', width: 300, position: 'top right' }, 'warning', 5000);
      this.toastr.warning('Invalid details');
    }
  }

  deleteExistingSubscription() {
    let customerReq = new CustomerCreate();
    customerReq.licenseId = this.userPlan.licenseId.toString();
    customerReq.companyId = this.userPlan.companyId;
    if (this.isSubscriptionActive) {
      this.subscription.push(
        this.intentService.updateSubscription<CustomerCreate>(customerReq).subscribe((data: any) => { this.addUserPlan(); }, ((error: any) => { })))
    }
  }
  confirmCardIntent() {
    let customerReq = new CustomerCreate();
    customerReq.licenseId = this.userPlan.licenseId.toString();
    customerReq.companyId = this.userPlan.companyId;
    this.subscription.push(
      this.intentService
        .confirmIntent<CustomerCreate>(customerReq)
        .subscribe((data: any) => {
          this.addUserPlan();
        },
        ((error: any) => {
          console.log(error);
          this.spinnerService.hide();
          //notify({ message: 'An error occured', width: 300, position: 'top right' }, 'error', 2000);
          this.toastr.error('An error occured.');
        }))
    )
  }
}
export class CardDetails {
  nameOnCard: string;
  email: string;
  isSubscription: boolean = false;
}
export class BankDetails {
  routingNumber: string;
  accountHolderName: string;
  accountHolderType: string;
  accountNumber: string;
  confirmAccountNumber: string;
}
