import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserAuthenticationService } from './services/user-authentication.service';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { VerifyUserComponent } from './register/verify-user/verify-user.component';
import { UserAuthenticatedService } from './services/user-authenticated.service';
import { CanLoadShocModuleService } from './services/can-load-shoc-module.service';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [UserAuthenticatedService] },
  { path: 'login', component: LoginComponent, canActivate: [UserAuthenticatedService] },
  { path: 'register', component: RegisterComponent, canActivate: [UserAuthenticatedService] },
  { path: 'verify', component: VerifyUserComponent },
  //Only Home Page is loaded first, Modules after login will load progressively
  {
    path: '', canActivate: [UserAuthenticationService], canLoad: [CanLoadShocModuleService], loadChildren: () => import('./shoc.module').then(m => m.ShocModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
