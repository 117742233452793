<br />
<div class="container">
  <div class="jumbotron shoc-bg shoc-shadow">
    <!--<h1 class="display-4" style="cursor:pointer"><b class="dark-font" routerLink="/home">SH<span style="color: white !important">O</span>C</b></h1>-->
    <a routerLink="/home" class="navbar-brand logo  animate__animated animate__fadeIn"><img src="../../assets/img/logo.png" alt=""></a>
    <p class="lead dark-font  animate__animated animate__fadeIn">Track your assets with SHOC, Make maintenance hassle-free</p>
    <hr style="border-top-color:black">
    <div class="row navbar flex-column flex-md-row">
      <div class="col-md-5">
        <div class="login-form animate__animated animate__fadeIn">
          <div class="card" style="box-shadow: 0 0 13px 1px #888888">
            <div class="card-header">
              ﻿<h2 class="card-title" style="text-align:center">Register</h2>
            </div>
            <div class="card-body">

              <form action="register-action" (submit)="onSubmit('registerDetailValidation')">
                <dx-form [formData]="company"
                         validationGroup="registerDetailValidation"
                         labelLocation="top">

                  <dxi-item dataField="companyName" [label]="{text: 'Name'}" [editorOptions]="{onInitialized: setFocus}" >
                    <dxi-validation-rule type="required" message="Username is required"></dxi-validation-rule>
                  </dxi-item>

                  <dxi-item dataField="companyEmail" [label]="{text: 'Work Email Address'}">
                    <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
                    <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                    <dxi-validation-rule type="pattern" [pattern]="emailPattern" message="Enter a valid company email"></dxi-validation-rule>
                  </dxi-item>

                  <dxi-item dataField="companyTelephoneNumber" [label]="{text: 'Contact Number'}" editorType="dxTextBox" [editorOptions]="{mask: '000-000-0000'}">
                    <dxi-validation-rule type="required" message="Contact Number is required"></dxi-validation-rule>
                  </dxi-item>

                </dx-form>

         
                <div class="form-group" style="margin-top: 1rem;text-align: center;margin-bottom: 0.0rem;">
                  <button type="submit" class="btn btn-primary shoc-btn dark-font">Register</button>&nbsp;
                  <button class="btn btn-light shoc-btn-secondary" routerLink="/home">Cancel</button>  
                  <br />
                  <div class="hr-or"><span>or</span></div>
                  <button class="btn btn-light shoc-btn-secondary"  routerLink="/login">Login</button>
                </div>
              </form>

              <!--===========================================================================================-->
              <!--<form class="form-class" #userlogin="ngForm">
              <div class="form-group">
                <label>Name</label>
                <dx-text-box id="txtName" name="name" class="form-control" [(ngModel)]="user.userName">
                </dx-text-box>
              </div>
              <div class="form-group">
                <label>Work Email Address</label>
                <dx-text-box mode="email" id="txtEmail" name="email" class="form-control" [(ngModel)]="company.companyEmail">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
                    <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="form-group">
                <label>Contact Number</label>
                <dx-text-box mode="phone" id="txtPhone" name="phone" class="form-control" [(ngModel)]="company.companyTelephoneNumber" mask="000-000-0000"
                             maskInvalidMessage="Enter valid phone number"
                             [useMaskedValue]="true">
                </dx-text-box>
              </div>
              <div class="form-group">
                <button class="btn btn-primary shoc-btn dark-font" (click)="onSubmit()">Register</button>&nbsp;
                <a class="btn btn-link" routerLink="/login">Login</a>
              </div>
            </form>-->

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="animate__animated animate__fadeIn">
          <img style="max-width: 340px; margin: 40px;" src="../../assets/user-plus-solid.svg" />
        </div>
      </div>
    </div>

  </div>
</div>

<!--<div *ngIf="isLoading" class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>-->

<ng-template #mailSentComponent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Email sent!</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    An email has been sent to {{company.companyEmail}}, Please verify to complete the registration.
  </div>
  <div class="modal-footer">
    <div class="row" style="width: 100%">
      <div class="col-4 text-left">
        <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
      </div>
      <div class="col-8 text-right">
        <button class="btn btn-light shoc-btn" (click)="closeModal()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>
