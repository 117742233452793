import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserAuthenticationService } from '../user-authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  //timeConst: number = Date.now();
  requestUri: { loginUri: string, registerUri: string, verifyEmailUri: string, enquiryUri: string, forgotPasswordUri:string} =
    {
      loginUri: "/api/UserController/UserDetails/Validate",
      registerUri: "/api/CompanyController/Company",
      verifyEmailUri: "/api/UserController/UserDetails/Verify",
      enquiryUri: "/api/SHOCEmail/shoc-enquiry",
      forgotPasswordUri: "/api/SHOCEmail/forgot-password",
    };

  constructor(
    private router: Router, private userAuthService: UserAuthenticationService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url == this.requestUri.loginUri || (req.method == "POST" && req.url == this.requestUri.registerUri)
      || req.url == this.requestUri.verifyEmailUri || req.url == this.requestUri.enquiryUri || req.url == this.requestUri.forgotPasswordUri) {
      return next.handle(req);
    }
    else {
      if (sessionStorage.getItem("token")) {
        // var defaults = '@ConfigurationManager.AppSettings["token"]';
        //let token = JSON.parse(this.cookie.get("UserData")).token;
        let token = sessionStorage.getItem("token");
        let tokenInfo = this.getDecodedAccessToken(token); // decode token
        let expireDate = tokenInfo.exp; // get token expiration dateTime


        if (Date.now() >= expireDate * 1000) {
          //this.cookie.deleteAll();
          sessionStorage.clear();
          //window.location.href = "/login";
          this.userAuthService.loggedInChange.next(false);
          this.router.navigate(['/login']);
          //notify({ message: 'Session Expired', width: 300, position: 'top right' }, 'warning', 600);
        }
        else {
          const req1 = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
          //return next.handle(req1);
          return next.handle(req1);
        }
      }
      else {
        return next.handle(req);
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
