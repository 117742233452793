<br />
<div class="container">
  <div class="jumbotron shoc-bg shoc-shadow">
    <!--<h1 class="display-4" style="cursor:pointer"><span class="dark-font" routerLink="/home">SH<b style="color: white">O</b>C</span></h1>-->
    <a routerLink="/home" class="navbar-brand logo animate__animated animate__fadeIn"><img src="../../assets/img/logo.png" alt=""></a>
    <p class="lead dark-font animate__animated animate__fadeIn">Track your assets with SHOC, Make maintenance hassle-free</p>
    <hr style="border-top-color:black">
    <div class="row navbar flex-column flex-md-row">
      <div class="col-md-5">
        <div class="login-form animate__animated animate__fadeIn">
          <div class="card" style="box-shadow: 0 0 13px 1px #888888">
            <div class="card-header">
              ﻿<h2 class="card-title" style="text-align:center">Login</h2>
            </div>
            <div class="card-body">
              <form action="login-action" (submit)="onSubmit('loginDetailValidation')">
                <dx-form [formData]="currentUser"
                         validationGroup="loginDetailValidation"
                         labelLocation="top">

                  <dxi-item dataField="userEmail" [label]="{text: 'Username'}" [editorOptions]="{onInitialized: setFocus}">
                    <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
                    <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                  </dxi-item>

                  <dxi-item dataField="userPassword" [label]="{text: 'Password'}" [editorOptions]="{mode: 'password'}">
                    <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
                  </dxi-item>

                </dx-form>

                <div class="form-group" style="margin-top:2rem;text-align: center;margin-bottom: 0.5rem;">
                  <button type="submit" class="btn btn-primary shoc-btn dark-font">Login</button>
                  <button class="btn btn-light shoc-btn-secondary" routerLink="/home">Cancel</button>
                  <br />
                  <a class="btn btn-link hyper-link" style="margin-top: 1rem;"
                     (click)="openResetPassword()">
                    Forgot Your Password?
                  </a>
                  <br />
                  <div class="hr-or"><span>or</span></div>
                  <button class="btn btn-light shoc-btn-secondary" routerLink="/register">Register</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="animate__animated animate__fadeIn">
          <img style="max-width: 300px;margin: 40px;" src="../../assets/tools-solid.svg" />
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #passwordRestPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Reset Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <dx-form id="passwordResetForm"
             #pswResetForm
             labelLocation="top"
             validationGroup="pswResetValidation">

      <dxi-item dataField="companyEmail" [label]="{text: 'Work Email Address'}" editorType="dxTextBox">
        <dxi-validation-rule type="required" message="Email is required"></dxi-validation-rule>
        <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
        <dxi-validation-rule type="pattern" [pattern]="emailPattern" message="Enter a valid email"></dxi-validation-rule>
      </dxi-item>

    </dx-form>
  </div>
  <div class="modal-footer">
    <div class="row" style="width: 100%;justify-content: center;">
      <button type="submit" class="btn btn-light shoc-btn" (click)="resetPassword(pswResetForm,'pswResetValidation')">Reset</button>
    </div>
  </div>
</ng-template>

<ng-template #userInactiveComponent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{inactiveType}} inactive!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="inactiveType=='User'">Your account is inactive. Please contact your administrator.</div>
    <div *ngIf="inactiveType=='Company'">Your company is inactive. Please contact SHOC administrator.</div>
  </div>
  <div class="modal-footer">
    <div class="row" style="width: 100%">
      <div class="col-4 text-left">
        <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
      </div>
      <div class="col-8 text-right">
        <button class="btn btn-light shoc-btn" (click)="modal.close()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>
