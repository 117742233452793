import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../app.constants';
import { LoginHistory } from '../model/loginhistory';

@Injectable({
  providedIn: 'root'
})
export class LoginHistoryService {

  loginHistoryUrl: string = '';

  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.loginHistoryUrl = this.config.loginHistoryApiUrl;
  }

  public getAllLoginHistory<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.loginHistoryUrl + '?companyId=' + companyId);
  }

  public getLoginHistoryById<T>(id: number): Observable<T> {
    return this.httpClient.get<T>(this.loginHistoryUrl + '/' + id);
  }

  public addLoginHistory<T>(loginHistory: LoginHistory): Observable<T> {
    return this.httpClient.post<T>(this.loginHistoryUrl, loginHistory);
  }

  public updateLoginHistory<T>(loginHistory: LoginHistory): Observable<T> {
    return this.httpClient.put<T>(this.loginHistoryUrl, loginHistory);
  }

  public deleteLoginHistory<T>(loginHistory: LoginHistory): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: loginHistory,
    };
    return this.httpClient.delete<T>(this.loginHistoryUrl, options);
  }
}
