import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyDetails } from '../model/company';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyDetailsUrl: string;
  private addCompanyDetailsUrl: string;
  private companyListReportUrl: string;
  private companyDetailReportUrl: string;
  companyUpdated: Subject<boolean> = new Subject<boolean>();

  constructor(private httpClient: HttpClient, private config: Configuration) {

    this.companyDetailsUrl = this.config.companyDetailsApiUrl;
    this.addCompanyDetailsUrl = this.config.addCompanyDetailsApiUrl;
    this.companyListReportUrl = this.config.companyListReportApiUrl;
    this.companyDetailReportUrl = this.config.companyDetailReportApiUrl;
  }

  public addCompany<T>(company: any): Observable<T> {
    return this.httpClient.post<T>(this.companyDetailsUrl, company);
  }
  public addNewCompany<T>(company: any): Observable<T> {
    return this.httpClient.post<T>(this.addCompanyDetailsUrl, company);
  }
  public updateCompany<T>(company: any): Observable<T> {
    return this.httpClient.put<T>(this.companyDetailsUrl, company);
  }

  public getAllCompany<T>(): Observable<T> {
    return this.httpClient.get<T>(this.companyDetailsUrl);
  }
  public getCompanyById<T>(companyId: string): Observable<T> {
    return this.httpClient.get<T>(this.companyDetailsUrl + '/' + companyId);
  }
  public getAllCompanyListReport<T>(id: string, reportType: string): string {
    let s = this.companyListReportUrl + '?id=' + id.toString() + '&reportType=' + reportType;
    return s;
  }
  public getCompanyDetailReport<T>(companyid: number, reportType: string): string {
    let s = this.companyDetailReportUrl + '?id=' + companyid.toString() + '&reportType=' + reportType;
    return s;
  }
}
