import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScreenList } from '../model/ScreenList';

@Injectable({
  providedIn: 'root'
})
export class ScreenListService {

  private getAllScreenListUrl: string;
  private getShocModulesUrl: string;
  public screenList: ScreenList[];

  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.getAllScreenListUrl = config.getAllScreenListApiUrl;
    this.getShocModulesUrl = config.getShocModulesApiUrl;
  }

  public getAllScreens<T>(): Observable<T> {
    return this.httpClient.get<T>(this.getAllScreenListUrl);
  }
  public getScreenModules<T>(): Observable<T> {
    return this.httpClient.get<T>(this.getShocModulesUrl);
  }
}
