import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { CompanyDetails } from '../model/company';
import { CompanyService } from '../services/company.service';
import { Subscription } from 'rxjs';
import { UserDetails } from '../model/userdetail';
import { CompanyUserDetails } from '../model/companydetails';
import { Router } from '@angular/router';
import { UserdetailsService } from '../services/userdetails.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { UserPermissionService } from '../services/user-permission.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from "guid-typescript";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  company: CompanyDetails;
  user: UserDetails;
  companyUser: CompanyUserDetails;
  subscriptions: Subscription[] = [];
  @ViewChild('mailSentComponent', { static: true }) mailSentComponent: TemplateRef<any>;
  modalOption: NgbModalOptions = {};
  //phonePattern: any = /^[0-9]+$/;
  //emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?!(?:gmail|outlook|yahoo|hotmail|aol|rediffmail|live|msn|iCloud|icloud|free|gmx|web|yandex|ymail|libero|uol|bol|mail|cox|sbcglobal|sfr|verizon|googlemail|ig|bigpond|terra|neuf|alice|rocketmail|att|laposte|facebook|bellsouth|charter|rambler|tiscali|shaw|sky|earthlink|optonline|freenet|t-online|aliceadsl|virgilio|home|qq|telenet|me|voila|planet|tin|ntlworld|arcor|frontiernet|hetnet|zonnet|club-internet|juno|optusnet|blueyonder|bluewin|skynet|sympatico|windstream|mac|centurytel|chello|aim|protonmail)\.(?:com|in|co.uk|co.in|fr|net|net.in|net.au|net.uk|com.br|ca|mx|de|it|ch|ru|es|nl|au|jp|be|com.ar|com.ul|com.mx|com.ar|com.au|com.sg|co.id|)$)/;

  companyList: CompanyDetails[];
  constructor(private companyService: CompanyService, private router: Router, private spinnerService: NgxSpinnerService,
    private userservice: UserdetailsService, private permissionService: UserPermissionService,
    private modalService: NgbModal, private toastr: ToastrService) {
    // this.getAllCompany();
  }

  ngOnInit() {
    this.company = new CompanyDetails;
    this.company.companyEmail = "";
    this.company.companyTelephoneNumber = "";
    this.user = new UserDetails;
    this.user.userName = "";
    this.ngbModalOptions();
  }
  private ngbModalOptions() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.centered = true;
    this.modalOption.size = "lg";
  }

  setFocus(e) {
    setTimeout(() => {
      e.component.focus();
    }, 0);
  }

  onSubmit(validationName) {
    this.spinnerService.show();
    let validationResult = validationEngine.validateGroup(validationName);
    if (validationResult.isValid) {
      this.AddCompany();
    }
    else {
      this.spinnerService.hide();
      //notify({ message: `Enter valid information`, width: 300, position: 'top right' }, 'warning', 600);
      this.toastr.warning(`Enter valid information.`);
    }

  }

  AddCompany() {
    let companyUser = new CompanyUserDetails();
    this.company.companyId = Guid.EMPTY;
    this.company.companyEmail = this.company.companyEmail;
    this.company.companyTelephoneNumber = this.company.companyTelephoneNumber;
    this.company.sessionTimeout = 7200;
    this.company.companyName = this.company.companyName;
    this.user.userName = this.company.companyName;
    //this.company.companyName = '';
    this.user.userEmail = this.company.companyEmail;
    this.formatPhone(this.company.companyTelephoneNumber);
    this.user.userPhoneNumber = this.company.companyTelephoneNumber;
    companyUser.company = this.company;
    companyUser.user = this.user;

    if (this.asyncValidation(this.company) == false) {
      this.subscriptions.push(
        this.companyService
          .addCompany(companyUser)
          .subscribe(
            (data: CompanyUserDetails) => {
              //console.log(data);
              this.companyUser = data;
              //this.setPermission();
              this.spinnerService.hide();
              this.modalService.open(this.mailSentComponent, this.modalOption);
            },
            error => {
              console.error('Email testing', error);
              let msg = error.error;
              const str1 = msg.split('~');
              let email = '';
              let err = ''
              if (str1.length > 0) {
                err = str1[0];
                email = str1[1];
              }
              if (err == "Duplicate") {
                //notify({ message: `Company already registered. Contact your admin ` + email.toString(), width: 300, position: 'top right' }, 'error', 600);
                this.toastr.error(`Company already registered. Contact your admin ` + email.toString());
              }
              this.spinnerService.hide();
            }));
    }
    else {
      this.spinnerService.hide();
      //notify({ message: 'Email is already registered.', width: 300, position: 'top right' }, 'error', 600);
      this.toastr.error('Email is already registered.');

    }
  }
  //setPermission() {
  //  let _permission = new Permission();
  //  _permission.companyId = this.companyUser.company.companyId;
  //  _permission.userPermissionId = 0;
  //  this.subscriptions.push(
  //    this.permissionService
  //      .setUserPermission(_permission)
  //      .subscribe((data) => {
  //        if (data) {
  //          //this.cookie.set("UserPermission", JSON.stringify(data));
  //          this.spinnerService.hide();
  //          //this.router.navigate(['/dashboard']);
  //          this.cookie.delete("UserData");

  //          this.modalService.open(this.mailSentComponent, this.modalOption);
  //        }
  //      }, error => {
  //        this.spinnerService.hide();
  //        console.log(error);
  //      })
  //  )
  //}
  closeModal() {
    this.company.companyEmail = "";
    this.company.companyTelephoneNumber = "";
    this.company.companyName = "";
    this.modalService.dismissAll();
    this.router.navigate(['/login']);
  }
  asyncValidation(params) {
    let result = false;
    //this.companyList = JSON.parse(localStorage.getItem('CompanyList'));
    if (this.companyList != null || this.companyList != undefined) {
      let isExist = this.companyList.find(f => f.companyEmail == params.companyEmail && f.companyId != params.companyId);
      if (isExist != null || isExist != undefined)
        result = true;
    }
    return result;
  }

  getAllCompany() {
    this.subscriptions.push(
      this.companyService
        .getAllCompany<CompanyDetails[]>()
        .subscribe(
          (data: CompanyDetails[]) => {
            //console.log(data);
            this.companyList = data;
          },
          error => {
            console.error(error);
          }));
  }

  formatPhone(input: string) {
    if (input != undefined && input != null && input != "") {
      if (input.length > 10) {
        this.company.companyTelephoneNumber = input;
      }
      else {
        let input1 = input.replace(/\\D/g, "");
        let part1 = input1.substring(0, 3),
          part2 = input1.substring(3, 6),
          part3 = input1.substring(6);
        this.company.companyTelephoneNumber = part1 + "-" + part2 + "-" + part3;
      }
    }
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }
}
