<!--<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>-->
<!--<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#ffc107" type="ball-clip-rotate-multiple" [fullScreen]="true">
  <p style="color: #ffc107"> SHOC loading..... </p>
</ngx-spinner>-->
<header class="navbar navbar-expand navbar-light flex-column flex-md-row bd-navbar shoc-bg shoc-shadow">
  <a class="navbar-brand mr-0 mr-md-2" [routerLink]="!isExpired ? ['/dashboard'] : []" aria-label="SHOC" (click)="dashboardLinkClick()">
    <span width="36" height="36" class="d-block" viewBox="0 0 612 612" role="img" focusable="false">
      <b class="dark-font" style="font-size:x-large;">SH<span style="color: white !important">O</span>C</b>
    </span>
  </a>
  <div class="navbar-nav-scroll" *ngIf="!isExpired">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [routerLinkActive]='["active"]'>
        <a class="nav-link"
           [routerLink]='["/dashboard"]' (click)="dashboardLinkClick()">
          <i class="fas fa-chart-line"></i>&nbsp;&nbsp;<span class="head-label">Dashboard</span>
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]='["active"]' [hidden]="!isMasterCompany || getModuleVisibility('Company')">
        <a class="nav-link"
           [routerLink]='["company-management/company-list"]'>
          <i class="fas fa-building"></i>&nbsp;&nbsp;<span class="head-label">Company Management</span>
        </a>
      </li>
      <li class="nav-item dropdown" [routerLinkActive]='["active"]' [hidden]="isMasterCompany || getModuleVisibility('Asset')">
        <a class="nav-link dropdown-toggle" href=""
           id="assetManagementDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-toolbox"></i>&nbsp;&nbsp;<span class="head-label">Asset management</span>
        </a>
        <div class="dropdown-menu shoc-bg" aria-labelledby="assetManagementDropdown">
          <div style="width:100%;text-align:center;" *ngIf="lookupLoading">
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.5s">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.75s">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:1s">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/asset-management/assets"]'
             [hidden]="getScreenPermission('Asset List')">
            <i class="fas fa-toolbox"></i>&nbsp;&nbsp;Assets
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/asset-management/service"]'
             [hidden]="getScreenPermission('Service')">
            <i class="fas fa-cogs"></i>&nbsp;&nbsp;Service
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/asset-management/service-history"]'
             [hidden]="getScreenPermission('Service History')">
            <i class="fas fa-history"></i>&nbsp;&nbsp;Service History
          </a>
        </div>
      </li>
      <li class="nav-item dropdown" [routerLinkActive]='["active"]' [hidden]="getModuleVisibility('Setup')">
        <a class="nav-link dropdown-toggle" href=""
           id="setupDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-cog"></i>&nbsp;&nbsp;<span class="head-label">Settings</span>
        </a>
        <div class="dropdown-menu shoc-bg" aria-labelledby="setupDropdown">
          <div style="width:100%;text-align:center;" *ngIf="lookupLoading">
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.5s">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.75s">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:1s">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/company-detail",_currentCompanyId]'
             [hidden]="getScreenPermission('Company Detail')">
            <i class="fas fa-building"></i>&nbsp;&nbsp;Company Details
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/permissions"]'
             [hidden]="getScreenPermission('Permissions')">
            <i class="fas fa-key"></i>&nbsp;&nbsp;Permissons
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/lookup-settings"]'
             [hidden]="getScreenPermission('Lookup Settings')">
            <i class="fas fa-th-list"></i>&nbsp;&nbsp;Lookup Settings
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/user-management/users"]'
             [hidden]="getScreenPermission('User List')">
            <i class="fas fa-user-cog"></i>&nbsp;&nbsp;User Management
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/plans"]'
             [hidden]="getScreenPermission('Plans')">
            <i class="fas fa-parking"></i>&nbsp;&nbsp;Plans
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/accounts"]'
             *ngIf="!isMasterCompany" [hidden]="getScreenPermission('Accounts')">
            <i class="fas fa-chalkboard-teacher"></i>&nbsp;&nbsp;Accounts
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/login-history"]'
             *ngIf="!isMasterCompany" [hidden]="getScreenPermission('Login History')">
            <i class="fas fa-clipboard-list"></i>&nbsp;&nbsp;Login History
          </a>

          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/assign-custom-plan"]'
             *ngIf="isMasterCompany" [hidden]="getScreenPermission('Custom Plan List')">
            <i class="fas fa-angle-double-right"></i>&nbsp;&nbsp;Assign Custom Plan
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/profile"]'
             [hidden]="getScreenPermission('Profile')">
            <i class="fas fa-id-card-alt">
            </i>&nbsp;&nbsp;Profile Management
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/service-alerts"]'
             *ngIf="!isMasterCompany" [hidden]="getScreenPermission('Service Alerts')">
            <i class="fas fa-bell"></i>&nbsp;&nbsp;Service Alerts
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/email-template"]'
             *ngIf="isMasterCompany" [hidden]="getScreenPermission('Email Template List')">
            <i class="fas fa-envelope-open-text"></i>&nbsp;&nbsp;Email Template
          </a>
          <a class="dropdown-item shoc-btn" [routerLinkActive]='["active"]' [routerLink]='["/settings/rename-custom"]'
             *ngIf="!isMasterCompany" [hidden]="getScreenPermission('Rename Custom')">
            <i class="fas fa-edit"></i>&nbsp;&nbsp;Rename Custom
          </a>
        </div>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav ml-md-auto">
    <li class="nav-item dropdown">
      <span class="nav-link mr-md-2">
        {{version}}
      </span>
    </li>
    <li class="nav-item dropdown" *ngIf="loggedInUser!= null">
      <a class="nav-link mr-md-2 login-circle shoc-btn" id="bd-profile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="">
        {{loggedInUserInitial}}
      </a>
      <div class="dropdown-menu dropdown-menu-md-right shoc-bg" aria-labelledby="bd-profile">
        <div class="card" style="border-radius: 16px; margin: 10px;">
          <div class="card-body">
            <h5 class="card-title" style="min-width: 200px; font-size: 16px !important;">{{loggedInUser.userName}}</h5>
            <h6 class="card-title" style="min-width: 200px; font-size: 14px !important;">{{getRoleName(loggedInUser.userRoleId)}}</h6>
            <h6 class="card-title" style="min-width: 200px; font-size: 12px !important;">{{loggedInUser.userEmail}}</h6>
            <p class="card-text">{{loggedInUser.company}}</p>
            <a class="btn btn-light shoc-btn" style="min-width:100px" [routerLink]='["/settings/profile"]'><i class="fas fa-user-circle"></i>&nbsp;&nbsp;Profile</a>
          </div><!--CODE CHANGE-->
        </div>
        <!--<a class="dropdown-item" [routerLinkActive]='["active"]' [routerLink]='["/settings/profile"]'>
    <i class="fas fa-user-circle"></i>&nbsp;&nbsp;Profile
  </a>-->
        <!--<a class="dropdown-item" href="">Change Password</a>-->
        <div class="dropdown-divider"></div>
        <a class="dropdown-item shoc-btn" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Logout
        </a>
      </div>
    </li>
  </ul>
</header>
