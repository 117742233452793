import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService implements CanActivate {

  loggedIn: boolean = false;
  loggedInChange: Subject<boolean> = new Subject<boolean>();
  permissionChange: Subject<boolean> = new Subject<boolean>();
  userDetailsChange: Subject<boolean> = new Subject<boolean>();
  dashboardLinkClicked: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.isAuthenticated()
      .then(
        (authenticated: boolean) => {
          if (authenticated == true) {
            this.loggedInChange.next(this.loggedIn);
            return true;
          }
          else {
            this.loggedInChange.next(this.loggedIn);
            this.router.navigate(['/home']);
            return false;
          }
        })
  }

  isAuthenticated() {
    //sessionStorage.setItem('userAccess', null)
    const promise = new Promise(
      (resolve, reject) => {
      //  let user = this.cookie.get("UserData");
      //  let user = localStorage.getItem("UserData");
        let user =  sessionStorage.getItem('UserData');
        if (user) {
          this.loggedIn = true;
            resolve(this.loggedIn);
          }
          else {
            this.loggedIn = false;
            resolve(this.loggedIn);
          }
      }
    );
    return promise;
  }
}
