export class UserDataUI {
  companyId: string;
  companyName: string;
  companyType: string;
  expire: string;
  planId: number;
  token: string;
  userId: string;
  userName: string;
  userRoleId: number;
  masterCompanyId: string;
  isUserActive: boolean;
  isCompanyActive: boolean;
  isCompanyAdmin: boolean;
  emailId: string;
}
