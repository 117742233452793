import { Resource } from './resource';
import { Equipment } from './equipment';
import { LicenseHistory } from './licensehistory';
import { ServiceHistory } from './servicehistory';
import { UserDetails } from './userdetail';

export class CompanyDetails extends Resource {
  no: number;
  companyId: string;
  //companyCode: number;
  companyNumber: number;
  companyName: string;
  companyDescription: string;
  companyTypeId: number;
  companyPlanId: number;
  companyEmail: string;
  companyWebsite: string;
  companyStreetNumberAndName: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
  companyTelephoneNumber: string;
  companyMobileNumber: string;
  companyFaxNumber: string;
  isActive: boolean;
  active: string;
  companyPIN: string;
  sessionTimeout: number;
  stripeCustomerCode: string;
  defaultPaymentId: string;
  isSubscription: boolean;
  equipment: Equipment[];
  licenseHistory: LicenseHistory[];
  //serviceHistory: ServiceHistory[];
  userDetails: UserDetails[];
}
