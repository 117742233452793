import { Injectable } from '@angular/core';

export const RoleID = { "ViewOnlyUser": 7, "Normal User": 8, "Admin": 9, "ShocAdmin": 0 };
export const CompanyTypeID = 16;

@Injectable()
export class Configuration {

  public Version = 'v1.1.54';

  readonly serviceURI: string = "/";
  //readonly serviceURI: string = "http://192.168.10.6/";

  readonly publickey = 'pk_test_51Gr31DDEk9Lf3YicAt1d1zLkUtjgeazQGHiLEk1vnRAjZKuKluFJLD5gr4Lu7JEkydweCJmBWwNskUuKsgPvMvvY00e9QP5aKI';

  //EQUIPMENT CONTROLLER URI
  public assetsApiUrl = this.serviceURI + 'api/EquipmentController/Equipment';
  public assetUpdateApiUrl = this.serviceURI + 'api/EquipmentController/EquipmentUpdate';
  public assetsGenerateServiceApiUrl = this.serviceURI + 'api/EquipmentController/Equipment/GenerateService';
  public assetsGetServiceApiUrl = this.serviceURI + 'api/EquipmentController/Equipment/GetService';
  public assetsByCompanyApiUrl = this.serviceURI + 'api/EquipmentController/EquipmentByCompany';
  public assetsSaveImportApiUrl = this.serviceURI + 'api/EquipmentController/EquipmentImport';
  public WarrantyFilesApiUrl = this.serviceURI + 'api/EquipmentController/WarrantyFiles';


  //SERVICE CONTROLLER URI
  public serviceTypeApiUrl = this.serviceURI + 'api/ServiceController/ServiceType';
  public serviceDetailApiUrl = this.serviceURI + 'api/ServiceController/ServiceDetail';
  public equipmentServiceApiUrl = this.serviceURI + 'api/ServiceController/EquipmentService';

  //SERVICE HISTORY CONTROLLER URI
  public serviceHistoryApiUrl = this.serviceURI + 'api/ServiceHistoryController/ServiceHistory';
  public serviceHistorSummaryForDashboardApiUrl = this.serviceURI + 'api/ServiceHistoryController/ServiceHistorSummaryForDashboard';
  public serviceHistoryByCompanyApiUrl = this.serviceURI + 'api/ServiceHistoryController/ServiceHistoryByCompany';

  //COMPANY CONTROLLER URI
  public companyDetailsApiUrl = this.serviceURI + 'api/CompanyController/Company';
  public addCompanyDetailsApiUrl = this.serviceURI + 'api/CompanyController/AddCompany';

  //USER CONTROLLER URI
  public userDetailsApiUrl = this.serviceURI + 'api/UserController/UserDetails/All';
  public userAuthApiUrl = this.serviceURI + 'api/UserController/Authenticate';
  public validateUserLoginApiUrl = this.serviceURI + 'api/UserController/UserDetails/Validate';
  public updateUserDetailsApiUrl = this.serviceURI + 'api/UserController/UserDetails';
  public verifyConnectionApiUrl = this.serviceURI + 'api/UserController/UserDetails/Verify';
  public userSessionApiUrl = this.serviceURI + 'api/UserController/UpdateUserSession';

  //LOOK UP CONTROLLER URI
  public lookUpParentApiUrl = this.serviceURI + 'api/LookupController/LookUpParent';
  public lookUpChildApiUrl = this.serviceURI + 'api/LookupController/LookUpChild';
  public lookUpChildByParentApiUrl = this.serviceURI + 'api/LookUpController/LookUpChild_Parent';
  public allLookUpChildByCompanyIDApiUrl = this.serviceURI + 'api/LookUpController/LookUpChildByCompanyID';

  //LICENSE CONTROLLER URI
  public licenseDetailsApiUrl = this.serviceURI + 'api/LicenseController/License';
  public licenseHistoryDetailsApiUrl = this.serviceURI + 'api/LicenseController/LicenseHistory';
  public getLicenseByCompanyIDApiUrl = this.serviceURI + 'api/LicenseController/LicenseByID';
  public getLicenseHistoryByCompanyIDApiUrl = this.serviceURI + 'api/LicenseController/LicenseHistoryByID';
  public isLicenseExpiredApiUrl = this.serviceURI + 'api/LicenseController/isLicenseExpired';

  //CUSTOM PLAN CONTROLLER URI
  public customPlanApiUrl = this.serviceURI + 'api/CustomPlanController/CustomPlan';

  //REPORT CONTROLLER URI
  public assetListReportApiUrl = this.serviceURI + 'api/ReportController/AssetListReport';
  public serviceHistoryReportApiUrl = this.serviceURI + 'api/ReportController/ServiceHistoryReport';
  public assetDetailReportApiUrl = this.serviceURI + 'api/ReportController/AssetDetailReport';
  public companyListReportApiUrl = this.serviceURI + 'api/ReportController/CompanyListReport';
  public companyDetailReportApiUrl = this.serviceURI + 'api/ReportController/CompanyDetailReport';
  public serviceAlertReportApiUrl = this.serviceURI + 'api/ReportController/ServiceAlertReport';



  //USER PERMISSION CONTROLLER URI
  public getUserPermissionApiUrl = this.serviceURI + 'api/UserPermissionController/UserPermission'
  public setUserPermissionApiUrl = this.serviceURI + 'api/UserPermissionController/SetUserPermission';

  //SCREEN LIST CONTROLLER URI
  public getAllScreenListApiUrl = this.serviceURI + 'api/ScreenListController/ScreenList';
  public getShocModulesApiUrl = this.serviceURI + 'api/ScreenListController/ShocModules';

  //LOGIN HISTORY CONTROLLER URI
  public loginHistoryApiUrl = this.serviceURI + 'api/LoginHistoryController/LoginHistory';

  //CUSTOM FIELD CONTROLLER URI
  public customFieldListApiUrl = this.serviceURI + 'api/CustomFieldController/CustomFieldList';
  public customFieldApiUrl = this.serviceURI + 'api/CustomFieldController/CustomFields';
  public generateCustomFieldsApiUrl = this.serviceURI + 'api/CustomFieldController/GenerateCustomFields';

  //PAYMENT URI
  public paymentIntentApiUrl = this.serviceURI + 'api/Payment/create-intent';
  public paymentCreateCustomerApiUrl = this.serviceURI + 'api/Payment/create-customer';
  public paymentMethodsApiUrl = this.serviceURI + 'api/Payment/payment-methods';
  public paymentOffSessionApiUrl = this.serviceURI + 'api/Payment/payment-offsession';
  public paymentSubscriptionApiUrl = this.serviceURI + 'api/Payment/payment-subscription';
  public getPaymentHistoryApiUrl = this.serviceURI + 'api/Payment/PaymentHistory';
  public verifyBankApiUrl = this.serviceURI + 'api/Payment/verify-bank';

 //SHOC EMails
  public enquiryApiUrl = this.serviceURI + 'api/SHOCEmail/shoc-enquiry';
  public resetPasswordApiUrl = this.serviceURI + 'api/SHOCEmail/forgot-password';
  public customPlanRequestApiUrl = this.serviceURI + 'api/SHOCEmail/shoc-plan-request';

  //SERVICE ALERT SETTINGS URI
  public alertSettingsApiUrl = this.serviceURI + 'api/AlertSettingsController/AlertSettings';

}
