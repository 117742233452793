<br />
<br />
<section id="faq-section">
  <div class="container animate__animated animate__fadeIn">
    <div class="row">
      <!-- Start Col -->
      <!--<div class="col-lg-6 col-md-12 pl-0 pr-5">
        <div class="faq-item-img">
          <img src="../../../assets/img/assetmanagment.png" class="img-fluid" alt="">
        </div>
      </div>-->
      <!-- End Col -->
      <!-- Start Col -->
      <div class="text-center mb-30">
        <h3 class="h3 b-600">Frequently Asked Questions</h3>  <br />
      </div>
      <br />
      <div class="col-lg-12 col-md-12 pl-4">
        <div class="container">
          <div id="accordion">
            <div class="card" *ngFor="let faq of faqList index as i" data-toggle="collapse" [attr.data-target]="'#child'+i">
              <div class="card-header" style="font-weight: bolder;">
                <!--<i class="fas fa-angle-down"></i>-->
                <i class="fas fa-angle-right"></i>
                <a class="card-link">
                  <!--data-toggle="collapse"-->
                  {{faq.question}}
                </a>
              </div>
              <div id="child{{i}}" class="collapse" data-parent="#accordion">
                <div class="card-body">
                  {{faq.answer}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Col -->

    </div>
  </div>
</section>
