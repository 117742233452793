import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentIntentService {

  private paymentIntentUrl: string;
  private paymentCustomerUrl: string;
  private paymentMethodsUrl: string;
  private paymentOffSessionUrl: string;
  private paymentSubscriptionUrl: string;
  private getPaymentHistoryUrl: string;
  private verifyBankUrl: string;

  public stripe_pk_Key: string;


  constructor(private httpClient: HttpClient, private config: Configuration) {
    this.paymentIntentUrl = this.config.paymentIntentApiUrl;
    this.paymentCustomerUrl = this.config.paymentCreateCustomerApiUrl;
    this.paymentMethodsUrl = this.config.paymentMethodsApiUrl;
    this.paymentOffSessionUrl = this.config.paymentOffSessionApiUrl;
    this.paymentSubscriptionUrl = this.config.paymentSubscriptionApiUrl;
    this.getPaymentHistoryUrl = this.config.getPaymentHistoryApiUrl;
    this.verifyBankUrl = this.config.verifyBankApiUrl;
    this.stripe_pk_Key = this.config.publickey;
  }

  public createIntent<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.paymentIntentUrl, request);
  }
  public confirmIntent<T>(request: any): Observable<T> {
    return this.httpClient.put<T>(this.paymentIntentUrl, request);
  }
  public createCustomer<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.paymentCustomerUrl, request);
  }
  public paymentMethods<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.paymentMethodsUrl, request);
  } 
  public getAllPaymentMethods<T>(id:string): Observable<T> {
    return this.httpClient.get<T>(this.paymentMethodsUrl + '?customerId=' + id);
  }
  public updatepaymentMethods<T>(request: any): Observable<T> {
    return this.httpClient.put<T>(this.paymentMethodsUrl, request);
  }
  public deletepaymentMethods<T>(request: any): Observable<T>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    return this.httpClient.delete<T>(this.paymentMethodsUrl, options);
  }

  public paymentOffSession<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.paymentOffSessionUrl, request);
  }

  public paymentSubscription<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.paymentSubscriptionUrl, request);
  }

  public updateSubscription<T>(request: any): Observable<T> {
    return this.httpClient.put<T>(this.paymentSubscriptionUrl, request);
  }

  public getPaymentHistory<T>(companyId): Observable<T> {
    return this.httpClient.get<T>(this.getPaymentHistoryUrl + '/' + companyId);
  }
  public verifyBank<T>(request: any): Observable<T> {
    return this.httpClient.post<T>(this.verifyBankUrl, request);
  }
}
