import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserdetailsService } from 'src/app/services/userdetails.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {

  userMessage;
  isInValid: boolean = true;
  subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute, private userservice: UserdetailsService) { }
 


  ngOnInit() {
    this.userMessage = "Verifying Account....."
    this.isInValid = true;
    var id = this.route.snapshot.queryParamMap.get("verify");
    var code = this.route.snapshot.queryParamMap.get("code");
   // var number = id;
    if (id===null || code == null) {
      this.userMessage = 'Unauthorized.';
    }
    else {     
     this.verifyUser(id, code);
    }
  }
  verifyUser(id, code) {
    this.subscriptions.push(
      this.userservice
        .verifyConnection<any>(id, code)
        .subscribe(
          (data: any) => {
            this.isInValid = false;
          },
          error => {
            console.error(error);
            this.userMessage = "Unable to verify account....."

          }));
  }

}
