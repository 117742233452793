import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.css']
})
export class FaqPageComponent implements OnInit {

  faqList: { question: string, answer: string }[] = [
    {
      question: "What is SHOC?",
      answer: "SHOC is a cloud-based asset maintenance management system. It allows you to save all service and maintenance history of your assets on the cloud, and gives you alerts about your upcoming tasks. It even keeps track of your assets warranties and easily prepares detailed reports to export."
    },
    {
      question: "Can you walk me through the SHOC website?",
      answer: "After the user logs in, they will see a dashboard that displays their assets. The three main sections are assets, service, and service history. Users will input all the necessary details pertaining to their asset. Under settings, they will see a variety of options to choose from such as company details, lookup settings, user management, plans, accounts, login history, service alerts and custom renaming."
    },
    {
      question: "What are the functionalities SHOC can provide?",
      answer: "Users can streamline asset and service management functions with SHOC. In addition to scheduling regular maintenance of assets, services pertaining to warranties, and unplanned breakdowns, repairs can be documented and tracked to maximize your assets lifecycle."
    },
    {
      question: "How do I register?",
      answer: "Registration is very simple! Go to shocapp.com/home, enter in your information and hit register. The page will refresh and you will be registered automatically for the 30 day free trial."
    },
    {
      question: "Are you going to charge me as soon as 30 days expire?",
      answer: "No, we will not charge you after your free trial expires. When your trial is about to end, we will send you an email notification asking if you would like to continue using SHOC. From there it is up to you if you’d like to sign up."
    },
    {
      question: "Which plan is the right one for me?",
      answer: "This depends on the size of your operation. We offer three standard plans to choose from based on the number of users and assets within your company. For custom plan information, please reach out to us at admin@shocapp.com. We can build a plan to suit your company’s needs!"
    },
    {
      question: "Our company has a night shift, is technical support available 24/7?",
      answer: "Though we do not have 24/7 support, we have an extremely responsive team that can be reached at admin@shocapp.com."
    },
    {
      question: "Is SHOC an application that is supported by Windows as well as IOS?",
      answer: "Yes, since it is a cloud-based application, it can be accessed on any operating system."
    },
    {
      question: "What type of training is available for new users?",
      answer: "There are a plethora of resources available to help you navigate the use of SHOC including tutorials and training videos. We can always be reached at admin@shocapp.com for any unanswered questions and concerns."
    },
    {
      question: "What are the system requirements for SHOC?",
      answer: "SHOC’s only system requirement is an internet connection and login information. No need to install any software."
    },
    {
      question: "Can a user access and perform work on SHOC without an internet connection?",
      answer: "Since SHOC is a cloud-based application, a working internet connection is required, at all times, for access, data entry and generations of reports."
    },
  ];
  constructor() { }

  ngOnInit() {
  }

}
