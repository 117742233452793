<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{Action}}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{Message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary shoc-btn-secondary" (click)="modal.close('cancel')">Cancel</button>
  <button type="button" ngbAutofocus class="btn btn-danger shoc-btn-danger" (click)="modal.close('success')">Ok</button>
</div>
