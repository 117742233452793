import { Resource } from './resource';
import { License } from './license';


export class LicenseHistory extends Resource {
  no: number;
  liceneseHistoryId: number;
  companyId: string;
  licenseId: number;
  licenseStartDate: string;
  licenseEndDate: string;
  isActive: boolean;
  active: string;
  license: License;
}
