<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="!defaultPayment">
  <dx-tab-panel [selectedIndex]="0"
                [loop]="false"
                [animationEnabled]="true"
                [swipeEnabled]="false"
                [showNavButtons]="true">
    <dxi-item title="Card Details" icon="fas fa-credit-card" *ngIf="paymentType=='Card'||paymentType==''">

      <div class="modal-body">

        <dx-form id="cardDetailsForm"
                 [formData]="cardDetails"
                 labelLocation="top"
                 validationGroup="cardDetValidation">

          <dxi-item itemType="group" [colCount]="1">

            <dxi-item dataField="nameOnCard">
              <dxi-validation-rule type="required" message="Name is required."></dxi-validation-rule>
            </dxi-item>
            <!--<dxi-item dataField="email">
              <dxi-validation-rule type="required" message="Email is required."></dxi-validation-rule>
              <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
            </dxi-item>-->

          </dxi-item>
          <!--<dxi-item dataField="isSubscription" [label]="{text: 'Save Card for future payments'}" editorType="dxSwitch" [colSpan]="2"></dxi-item>-->

          <dxi-item itemType="group" [colCount]="1">
            <div class="card-field">
              <div #cardElement></div>
            </div>
          </dxi-item>

        </dx-form>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%">
          <div class="col-4 text-left">
            <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
          </div>
          <div *ngIf="!addPayments" class="col-8 text-right">
            <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
            <button type="submit" class="btn btn-light shoc-btn" [disabled]="isDisabled" (click)="!isDisabled?submitPayment('cardDetValidation',true):false">
              Pay Now
            </button>
          </div>
          <div *ngIf="addPayments" class="col-8 text-right">
            <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
            <button type="submit" class="btn btn-light shoc-btn" [disabled]="isDisabled" (click)="addCardPaymentMethod('cardDetValidation')">
              Add
            </button>
          </div>
        </div>
      </div>
    </dxi-item>

    <dxi-item title="Bank Details" icon="fas fa-university" *ngIf="paymentType=='Bank'||paymentType==''">
      <div class="modal-body">
        <dx-form id="bankDetailsForm"
                 [formData]="bankDetails"
                 labelLocation="top" validationGroup="bankDetValidation">

          <dxi-item itemType="group" [colCount]="2">

            <dxi-item dataField="accountHolderName">
              <dxi-validation-rule type="required" message="Name is required."></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="routingNumber">
              <dxi-validation-rule type="required" message="Routing Number is required."></dxi-validation-rule>
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="accountNumber">
              <dxi-validation-rule type="required" message="Account Number is required."></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="confirmAccountNumber" [editorOptions]="{ mode: 'password' }">
              <dxi-validation-rule type="required" message="Account Number mismatch."></dxi-validation-rule>
            </dxi-item>
          </dxi-item>
        </dx-form>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%">
          <div class="col-4 text-left">
            <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
          </div>
          <div *ngIf="!addPayments" class="col-8 text-right">
            <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
            <button type="submit" class="btn btn-light shoc-btn" (click)="!isDisabled?submitPayment('bankDetValidation',false):false">Pay</button>
          </div>
          <div *ngIf="addPayments" class="col-8 text-right">
            <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
            <button type="submit" class="btn btn-light shoc-btn" [disabled]="isDisabled" (click)="addBankPaymentMethod('bankDetValidation')">
              Add
            </button>
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-tab-panel>
</div>
<div *ngIf="defaultPayment&&!isSubscriptionActive">
  <div class="modal-body">
    <div class="card bg-light text-dark">
      <div class="card-body" style="color:black">
        <div style="width:100%;text-align:center;" *ngIf="!userPaymentMethods">
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.5s">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.75s">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:1s">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <div *ngIf="userPaymentMethods && userPaymentMethods.methodType=='card'">
              <b>Paying with card</b>
              <ul style="list-style:none">
                <li><span style="padding-right:30px">Card Type</span>: <b>{{userPaymentMethods.brand | titlecase }}</b></li>
                <li><span style="padding-right:5px">Card Number</span>: <b>XXXX-XXXX-XXXX-{{userPaymentMethods.last4}}</b></li>
                <li><span style="padding-right:30px">Expire On</span>: <b>{{userPaymentMethods.expMonth}}/{{userPaymentMethods.expYear}}</b></li>
              </ul>
            </div>
            <div *ngIf="userPaymentMethods && userPaymentMethods.methodType=='bank_account'">
              <b>Paying with bank</b>
              <ul style="list-style:none">
                <li><span style="padding-right:5px">Account Holder Name</span>: <b>{{userPaymentMethods.bankHolderName | titlecase }}</b></li>
                <li><span style="padding-right:44px">Account Number</span>: <b>XXXX-XXXX-XXXX-{{userPaymentMethods.last4}}</b></li>
                <li><span style="padding-right:127px">Bank</span>: <b>{{userPaymentMethods.brand | titlecase }}</b></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3" style="text-align-last: center;">
            <i class="fas fa-credit-card" style="font-size: 100px;" *ngIf="userPaymentMethods && userPaymentMethods.methodType=='card'"></i>
            <i class="fas fa-university" style="font-size: 100px;" *ngIf="userPaymentMethods && userPaymentMethods.methodType=='bank_account'"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row" style="width: 100%">
      <div class="col-4 text-left">
        <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
      </div>
      <div class="col-8 text-right">
        <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
        <button type="submit" class="btn btn-light shoc-btn" (click)="!isDisabled?offSessionPayment():false">Pay</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isSubscriptionActive">
  <div class="modal-body">
    <div class="card bg-light text-dark">
      <div class="card-body" style="color:black">
        <div style="width:100%;text-align:center;" *ngIf="!userPaymentMethods">
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.5s">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:0.75s">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow" role="status" style="width:1rem;height:1rem;animation-delay:1s">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <div *ngIf="userPaymentMethods && userPaymentMethods.methodType=='card'">
              <b>Paying with card</b>
              <ul style="list-style:none">
                <li><span style="padding-right:30px">Card Type</span>: <b>{{userPaymentMethods.brand | titlecase }}</b></li>
                <li><span style="padding-right:5px">Card Number</span>: <b>XXXX-XXXX-XXXX-{{userPaymentMethods.last4}}</b></li>
                <li><span style="padding-right:30px">Expire On</span>: <b>{{userPaymentMethods.expMonth}}/{{userPaymentMethods.expYear}}</b></li>
              </ul>
            </div>
            <div *ngIf="userPaymentMethods && userPaymentMethods.methodType=='bank_account'">
              <b>Paying with bank</b>
              <ul style="list-style:none">
                <li><span style="padding-right:5px">Account Holder Name</span>: <b>{{userPaymentMethods.bankHolderName | titlecase }}</b></li>
                <li><span style="padding-right:44px">Account Number</span>: <b>XXXX-XXXX-XXXX-{{userPaymentMethods.last4}}</b></li>
                <li><span style="padding-right:127px">Bank</span>: <b>{{userPaymentMethods.brand | titlecase }}</b></li>
              </ul>
            </div>
          </div>
          <div class="col-md-3" style="text-align-last: center;">
            <i class="fas fa-credit-card" style="font-size: 100px;" *ngIf="userPaymentMethods && userPaymentMethods.methodType=='card'"></i>
            <i class="fas fa-university" style="font-size: 100px;" *ngIf="userPaymentMethods && userPaymentMethods.methodType=='bank_account'"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row" style="width: 100%">
      <div class="col-4 text-left">
        <!--<button type="button" class="btn btn-light shoc-btn-danger" (click)="deletePlan()" [hidden]="popupTitle=='Add'">Delete</button>-->
      </div>
      <div class="col-8 text-right">
        <button type="button" class="btn btn-light shoc-btn-secondary" (click)="modal.dismiss('Close')">Cancel</button>
        <button type="submit" class="btn btn-light shoc-btn" (click)="!isDisabled?offSessionPayment():false">OK</button>
      </div>
    </div>
  </div>
</div>
