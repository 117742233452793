<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&amp;display=swap" rel="stylesheet">
</head>
<body>
  <table border="0" cellpadding="0" cellspacing="0" style="background: #f5f5f5; width: 100%;">
    <tbody>
      <tr>
        <td style="padding: 10px 10px 30px;">
          <table border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 700px; margin: 0 auto; background: #fff;">
            <tbody>
              <tr>
                <td>
                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                    <tbody>
                      <tr>
                        <td style="text-align: center; padding: 0px 0 0px; background: #ffd91e;">
                          <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                            <tbody>
                              <tr>
                                <td style="text-align: center; padding: 0 20px;"><a href="#"><img style="width: 300px; display: inline-block;" src="../../../assets/img/logo.png"></a></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding: 0px 0;"><img style="width: 100%;" src="../../../assets/img/banner.png"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                    <tbody>
                      <tr>
                        <td style="padding: 20px 20px; color: #484848; font-size: 14px; text-align: left; line-height: 25px;">
                          <div *ngIf="!isInValid">
                            <h2 style="font-size: 22px; line-height: 33px;">Verification Completed</h2>
                            <p style="font-size: 16px; line-height: 28px;">Verification successfully completed, your password is sent to your registered email.</p>
                            <p style="font-size: 16px; line-height: 28px;">Please Check.</p>
                          </div>
                          <div *ngIf="isInValid">
                            <h2 style="font-size: 22px; line-height: 33px;">{{userMessage}}</h2>                            
                          </div>
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%; background: #f5f5f5;">
                    <tbody>
                      <tr>
                        <td style="text-align: center; padding: 20px 0;">
                          <p style="color: #262337; font-size: 16px; line-height: 26px; margin: 0;">Copyright &copy; S H O C - Service History on Cloud</p>
                          <span style="color: #474747; font-size: 10px;">Copyright 2020. All rights reserved. </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</body>
</html>
