import { UserDetails } from './userdetail';
import { CompanyDetails } from './company';

export class LoginHistory {
  no: number;
  loginHistoryId: number;
  userId: string;
  companyId: string;
  logInTime: Date;
  logOutTime: Date;
  description: string;
  userDetails: UserDetails;
  companyDetails: CompanyDetails;

}
